export interface ISaoRecord {
    subversions: string[];
    data:        ISaoRecordData;
    id:          string;
    version:     string;
    kind:        string;
    createTime:  Date;
}

export interface ISaoRecordData {
    wellID:             string;
    plannedWell:        boolean;
    externalPropertyId: string;
    externalWellName:   string;
    assignedSaoOptions: IAssignedSaoOption[];
    assetTeam:          string;
    developmentArea:    string;
    subDevelopmentArea: string;
    forecastedFrac:     string;
    forecastedPop:      string;
    forecastedTdt:      string;
    formationCode:      string;
    padName:            string;
    afeName:            string;
    spudDate:           string;
    targetFormation:    string;
    typeCurve:          string;
    verticalHorizontal: string;
    wellLongitude:      string;
    wellLatitude:     string;
}

export interface IAssignedSaoOption {
    comment:    string;
    files:      IFile[];
    index:      string;
    optionId:   string;
    optionType: OptionType;
    addedBy:    string;
    dataAcquisitionTime: string;
    tempOptionGuid: string;
}

export interface IFile {
    fileName:       string;
    fileVersions:   IFileVersion[]
}

export interface IFileVersion{
    versionComment: string;
    file:           string;
    blobName:       string;
    fileName:       string;
    tempFileGuid:   string;
}

export enum OptionType {
    Planned = "Planned",
    Acquired = "Acquired"
}
