import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { FetchSaoOptionsActions, SaoOptionsRequestFailed, SaoOptionsRequestSuccess } from "../actions/fetch-sao-options.actions";
import { IAppState } from "../reducers";

@Injectable()
export class FetchSaoOptionsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) { }

    public fetchSaoOptions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchSaoOptionsActions.SAO_OPTIONS_REQUEST),
            exhaustMap(() => {
                return this.dataService.getAllSaoOptions().pipe(
                    map(result => {
                        return new SaoOptionsRequestSuccess({ options: result, optionsLoading: false, optionsRequestError: null });
                    }))
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new SaoOptionsRequestFailed({ options: [], optionsLoading: false, optionsRequestError: error }));
                throw err;
            })
        )
    }
    );
}