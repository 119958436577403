import { Action } from "@ngrx/store"
import { ISaoRecordsCacheState } from "../reducers/fetch-records.reducer";

export enum FetchSaoRecordsActions {
    SAO_RECORDS_REQUEST = '[SAO_RECORDS_CACHE] Request SAO Records',
    SAO_RECORDS_REQUEST_SUCCESS = '[SAO_RECORDS_CACHE] SAO Records request - success',
    SAO_RECORDS_REQUEST_FAILED = '[SAO_RECORDS_CACHE] SAO Records request - failed' 
}

export class RequestRecords implements Action {
    readonly type: string = FetchSaoRecordsActions.SAO_RECORDS_REQUEST;
    constructor(public payload: ISaoRecordsCacheState = <ISaoRecordsCacheState>{}) {}
}

export class RecordsRequestSuccess implements Action {
    readonly type: string = FetchSaoRecordsActions.SAO_RECORDS_REQUEST_SUCCESS;
    constructor(public payload: ISaoRecordsCacheState) {}
}

export class RecordsRequestFailed implements Action {
    readonly type: string = FetchSaoRecordsActions.SAO_RECORDS_REQUEST_FAILED;
    constructor(public payload: ISaoRecordsCacheState) {}
}

export type FetchRecordsAction 
= RequestRecords | RecordsRequestSuccess | RecordsRequestFailed;