import { ISaoRecord } from "src/app/models/osdu/sao-record.model";
import { FetchRecordsAction, FetchSaoRecordsActions } from "../actions/fetch-records.actions";

export interface ISaoRecordsCacheState {
    records: ISaoRecord[],
    recordsLoading: boolean,
    recordsRequestError: any
}

const initialState: ISaoRecordsCacheState = {
    records: [],
    recordsLoading: false,
    recordsRequestError: null
}

export function saoRecordsReducer(state: ISaoRecordsCacheState = initialState, action: FetchRecordsAction): ISaoRecordsCacheState
{
    switch(action.type) {
        case FetchSaoRecordsActions.SAO_RECORDS_REQUEST: {
            state = <ISaoRecordsCacheState> {
                records: [], recordsLoading: true, recordsRequestError: null
            };
            return state;
        }
        case FetchSaoRecordsActions.SAO_RECORDS_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchSaoRecordsActions.SAO_RECORDS_REQUEST_FAILED: {
            state = <ISaoRecordsCacheState>{
                records: [],
                recordsLoading: false,
                recordsRequestError: action.payload.recordsRequestError
            };
            return state;
        }
        default: {
            return state;
        }
    }
}

