import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { IAppState } from 'src/app/store/reducers';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';


@Component({
  selector: 'sao-sao-option-record-add-dialog',
  templateUrl: './sao-option-record-add-dialog.component.html',
  styleUrls: ['./sao-option-record-add-dialog.component.scss']
})
export class SaoOptionRecordAddDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  addOptsDataSource: MatTableDataSource<ISaoOption> = new MatTableDataSource<ISaoOption>();
  allSaoOptionsData: ISaoOption[] = [];
  private saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;
  private saoOptionsLoading: boolean = false;
  quickSearchInputValue: string = "";


  initialSelection: ISaoOption[] = [];
  allowMultiSelect: boolean = true;
  saoOptionsTableMultiselection = new SelectionModel<ISaoOption>(this.allowMultiSelect, this.initialSelection);

  @ViewChild(MatSort)
  private readonly sort!: MatSort;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISaoOption,
    private changeDetector: ChangeDetectorRef,
    store: Store<IAppState>) {
    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));
  }

  ngOnInit(): void {
    this.getSaoOptions();
  }

  ngAfterViewInit(): void {
    let availableOpts: ISaoOption[] = [];

    this.allSaoOptionsData.forEach(opt => {
      let option = this.allSaoOptionsData.find(option => option.id === opt.id);
      if (option !== undefined) {
        availableOpts.push(option);
      }
    });

    this.addOptsDataSource = new MatTableDataSource<ISaoOption>(availableOpts);
    this.addSorting();
    this.changeDetector.detectChanges();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onSaoOptionsRowClick(data: ISaoOption) {
    if (this.saoOptionsTableMultiselection.isSelected(data)) {
      this.saoOptionsTableMultiselection.deselect(data);
    } else {
      this.saoOptionsTableMultiselection.select(data);
    }
  }

  getSaoOptions() {
    let sub = this.saoOptionsCacheState$.subscribe(state => {
      this.allSaoOptionsData = state.options;
      this.saoOptionsLoading = state.optionsLoading;
    });

    this.subscriptions.push(sub);
  }

  addSorting() {
    this.addOptsDataSource.sort = this.sort;
    this.addOptsDataSource.sortingDataAccessor =
      (row: any, columnName: string): string => row.data[columnName];
  }

  applyQuickSearchFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase(); // Remove whitespace and convert to lowercase

    this.addOptsDataSource.filterPredicate = (saoOption: ISaoOption, filter: string) => {
      const searchTerm = filterValue;
      const itemValue = saoOption.data.saoOptionName.toLowerCase(); // Assuming 'name' is the property you want to filter on

      return itemValue.includes(searchTerm);
    };

    this.addOptsDataSource.filter = filterValue;
    this.addOptsDataSource.filter = filterValue;

    this.changeDetector.detectChanges();
  }
  customSaoOptionFilterPredicate(saoOption: ISaoOption, filterValue: string) {
    return saoOption.data.saoOptionName.includes(filterValue);
  }

  availableSaoOptColumns = [
    {
      columnDef: "actions",
      header: "",
      cell: (record: ISaoOption) => { },
    },
    {
      columnDef: "saoOptionName",
      header: "Sort SA&O",
      cell: (option: ISaoOption) => `${option.data.saoOptionName}`
    }
  ];

  displayedAvailableSaoOptCols = this.availableSaoOptColumns.map(c => c.columnDef);
}
