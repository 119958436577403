import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, combineLatestWith } from 'rxjs';
import { IBasin } from 'src/app/models/osdu/basin.model';
import { IField } from 'src/app/models/osdu/field.model';
import { IFileUploadOsduResponse } from 'src/app/models/files/file-upload-osdu-response.model';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { IFileVersion, ISaoRecord } from 'src/app/models/osdu/sao-record.model';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { UpdateService } from 'src/app/services/update.service';
import { IAppState } from 'src/app/store/reducers';
import { IBasinsCacheState } from 'src/app/store/reducers/fetch-basins.reducer';
import { IFieldsCacheState } from 'src/app/store/reducers/fetch-fields.reducer';
import { ISaoRecordsCacheState } from 'src/app/store/reducers/fetch-records.reducer';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SaoOptionsComponent } from '../shared/sao-options/sao-options.component';
import { environment } from 'src/environments/environment';
import { RequestRecords } from 'src/app/store/actions/fetch-records.actions';
import { IUpsertRecordPayload, IUpsertSaoOptionsPayload } from 'src/app/models/payloads/upsert-record-payload.model';
import { SaoRecordService } from 'src/app/services/sao-record.service';
import { IAssignedSaoOptionDto, IUploadedFileDto } from 'src/app/models/sao-option-dto.model';
import { GenericDialogService } from '../../generic-dialog/generic-dialog.service';
import { EditServiceEvent } from 'src/app/services/edit-service-event.service';
import { IWellsDetailsCacheState } from 'src/app/store/reducers/fetch-wells-details-reducer';
import { ISubFieldsCacheState } from 'src/app/store/reducers/fetch-sub-fields.reducer';

@Component({
  selector: 'sao-edit-record',
  templateUrl: './edit-record.component.html',
  styleUrls: ['./edit-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None

})
export class EditRecordComponent implements OnInit, AfterViewInit {

  private subscriptions: Subscription[] = [];
  private saoRecordsLoading: boolean = false;
  private saoOptionsLoading: boolean = false;
  private basinsLoading: boolean = false;
  private fieldsLoading: boolean = false;
  submitLoading: boolean = false;

  public expanded: boolean = false;

  allSaoRecords: ISaoRecord[] = [];
  private saoRecordsCacheState$: Observable<ISaoRecordsCacheState>;

  acqOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();
  plannedOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();

  allSaoOptionsData: ISaoOption[] = [];
  private saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;

  basinRecords: IBasin[] = [];
  submitting: boolean = false;
  private basinsCacheState$: Observable<IBasinsCacheState>;

  //field == dev area
  fieldOsduRecords: IField[] = [];
  private fieldsCacheState$: Observable<IFieldsCacheState>;
  private subFieldsCacheState$: Observable<ISubFieldsCacheState>;
  devAreaDropdownList: IField[] = [];

  //sub Field == sub dev area
  subFieldOsduRecords: IField[] = [];
  subDevAreaDropdownList: IField[] = [];
  subFields = new FormControl();

  options = new FormControl();
  optionsDropdownList: ISaoOption[] = [];

  private wellsDetailsCacheState$: Observable<IWellsDetailsCacheState>;
  private wellsDetailsLoading: boolean = false;

  editRecordForm: FormGroup = new FormGroup({
    assignedSaoOptions: new FormArray([]),
    assetTeam: new FormControl({ value: '', disabled: true }),
    devArea: new FormControl({ value: '', disabled: true }),
    subDevelopmentArea: new FormControl({ value: '', disabled: true }),
    padName: new FormControl({ value: '', disabled: true }),
    externalWellName: new FormControl({ value: '', disabled: true })
  });

  @ViewChild("assetTeamDropdown") assetTeamDropdownControl!: MatSelect;
  @ViewChild("devAreaDropdown") devAreaDropdownControl!: MatSelect;
  @ViewChild("subDevAreaDropdown") subDevAreaDropdownControl!: MatSelect;
  @ViewChild("tgtFormationsDropdown") tgtFormationsDropdownControl!: MatSelect;
  @ViewChild("saoOptionsDropdown") saoOptionsDropdownControl!: MatSelect;
  @ViewChild("saoOptionsComponent") saoOptionsComponentControl!: SaoOptionsComponent;
  OptionType: any;


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private updateService: UpdateService,
    private fileUploadService: FileUploadService,
    private saoRecordService: SaoRecordService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: GenericDialogService,
    private editServiceEvent: EditServiceEvent,
    private store: Store<IAppState>) {
    this.saoRecordsCacheState$ = store.pipe(select('saoRecordsCacheState'));
    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));
    this.basinsCacheState$ = store.pipe(select('basinsCacheState'));
    this.fieldsCacheState$ = store.pipe(select('fieldsCacheState'));
    this.subFieldsCacheState$ = store.pipe(select('subFieldsCacheState'));
    this.wellsDetailsCacheState$ = store.pipe(select('wellsDetailsCacheState'));
  }

  assetTeamSelected!: IBasin | undefined;
  devAreaSelected!: IField | undefined;
  subDevAreasSelected!: IField | undefined;
  saoRecord!: ISaoRecord;

  ngOnInit() {

    this.saoRecordService.getSaoRecords(this);

    let recordId = this.activatedRoute.snapshot.paramMap.get('saoRecordId') ?? "";

    this.saoRecord = this.allSaoRecords.find(record => record.id === recordId) ?? <ISaoRecord>{};

    let externalPropertyId: string = this.saoRecord.data.externalPropertyId;
    this.mapWellDetails(externalPropertyId);

    let assignedSaoOptionsArray = this.editRecordForm.get('assignedSaoOptions') as FormArray;

    this.saoRecord.data.assignedSaoOptions.forEach(x => {
      const newFormGroup = new FormGroup({
        comment: new FormControl(''),
        files: new FormControl(''),
        index: new FormControl(''),
        optionId: new FormControl(''),
        optionType: new FormControl(''),
        addedBy: new FormControl(''),
        dataAcquisitionTime: new FormControl(''),
        tempOptionGuid: new FormControl('')
      });

      assignedSaoOptionsArray.push(newFormGroup);
    });

    this.editRecordForm.setValue({
      assetTeam: '',
      assignedSaoOptions: this.saoRecord.data.assignedSaoOptions,
      devArea: this.saoRecord.data.developmentArea,
      subDevelopmentArea: this.saoRecord.data.subDevelopmentArea,
      padName: this.saoRecord.data.padName,
      externalWellName: this.saoRecord.data.externalWellName
    });

    this.saoRecordService.getBasins(this);

    this.getFields();

    this.editServiceEvent.editEvent.subscribe((evt: boolean) => {
      if (evt) {
        this.submitEditRecord();
        this.editServiceEvent.editRecord(false);
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.setFormGroupDefaultValues();
    this.changeDetectorRef.detectChanges();
  }

  getFields() {
    let sub = this.fieldsCacheState$.pipe(
      combineLatestWith(this.subFieldsCacheState$)
    )
      .subscribe(([fieldsCacheContent, subFieldsCacheContent]) => {
        this.fieldsLoading = fieldsCacheContent.fieldsLoading && subFieldsCacheContent.subFieldsLoading;
        this.fieldOsduRecords = [...fieldsCacheContent.fields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
        this.subFieldOsduRecords = [...subFieldsCacheContent.subFields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
      });

    this.subscriptions.push(sub);
  }

  mapWellDetails(externalPropertyId: string) {
    let sub = this.wellsDetailsCacheState$.subscribe(state => {
      this.wellsDetailsLoading = state.wellsDetailsLoading;
      if (!state.wellsDetailsLoading) {
        let wellDetails = state.wellsDetailsList.find(wellDetails => wellDetails.mudiPropertyId === externalPropertyId);
        if (wellDetails) {
          let tempSaoRecord = JSON.parse(JSON.stringify(this.saoRecord)) as ISaoRecord;
          tempSaoRecord.data.developmentArea = wellDetails.devArea;
          tempSaoRecord.data.subDevelopmentArea = wellDetails.subDevArea;
          tempSaoRecord.data.assignedSaoOptions.forEach(option => {
            option.tempOptionGuid = crypto.randomUUID();
          });
          this.saoRecord = tempSaoRecord;
        }
      };
    })
    this.subscriptions.push(sub);
  }

  setFormGroupDefaultValues() {
    this.fieldOsduRecords.forEach(field => {
      if (field.data.fieldName == this.editRecordForm.controls['devArea'].value) {
        this.basinRecords.forEach(basin => {
          if (basin.id == field.data.geoContexts[0].basinId) {
            this.editRecordForm.controls['assetTeam'].setValue(basin.data.basinName);
          }
        });
      }
    })
  }

  cancel(event: Event) {
    this.router.navigate(['records']);
  }

  expandEvent(event: MatExpansionPanel): void {
    this.expanded = event.expanded;
  }

  isDataLoading(): boolean {
    return (this.saoRecordsLoading
      || this.saoOptionsLoading
      || this.basinsLoading
      || this.fieldsLoading
      || this.submitLoading);
  }

  async submitEditRecord() {
    this.submitLoading = true;

    this.acqOptsDataSource.data = this.saoOptionsComponentControl.acqOptsDataSource.data;
    this.plannedOptsDataSource.data = this.saoOptionsComponentControl.plannedOptsDataSource.data;

    let allFilesForUpload = this.acqOptsDataSource.data
      .map(saoOption => saoOption.files).flat(1);

    let functionResponse = await this.fileUploadService.uploadFileFromStageToOsdu(allFilesForUpload);
    let msg = 'Upload file(s) failed!';

    if (functionResponse && functionResponse?.runtimeStatus === 'Completed' &&
      functionResponse?.customStatus !== 'Failed' && functionResponse?.output) {
      this.getExistingSaoOptionsData(functionResponse.output);
      this.sendUpsertSaoRecordRequest();
    } else {
      this.errorDialog(msg);
      this.submitLoading = false;
    }

    this.changeDetectorRef.detectChanges();
  }

  getExistingSaoOptionsData(osduResponses: IFileUploadOsduResponse[]) {
    osduResponses.forEach(osduResponse => {
      let fileUploadedPredicate = (fileUploaded: IUploadedFileDto) =>
        fileUploaded.fileVersions.some(fileVersion => fileVersion.blobName === osduResponse.blobName);
      let saoOptionByBlobNameAndFileNamePredicate = (saoOption: any) =>
        saoOption.files.some(fileUploadedPredicate)

      let saoOption = this.acqOptsDataSource.data.find(x => saoOptionByBlobNameAndFileNamePredicate(x));
      let saoOptionIndex = this.acqOptsDataSource.data.findIndex(x => saoOptionByBlobNameAndFileNamePredicate(x));

      if (saoOption?.files.some(x => x.fileVersions.some(fileVersion => fileVersion.blobName === osduResponse.blobName
        && osduResponse.isUploadedToOsdu === true))) {
        let fileUploadedIndex: number = saoOption?.files.findIndex((fileUploaded: IUploadedFileDto) =>
          fileUploaded.fileVersions.some(fileVersion => fileVersion.blobName == osduResponse.blobName) ?? -1);

        if (fileUploadedIndex < 0) {
          return;
        }

        let versionIndex = saoOption?.files[fileUploadedIndex]?.fileVersions.findIndex(x => x.blobName === osduResponse.blobName);

        if (versionIndex < 0) {
          return;
        }

        let fileGuid = this.acqOptsDataSource.data[saoOptionIndex].files[fileUploadedIndex].fileVersions[versionIndex].tempFileGuid;
        this.acqOptsDataSource.data[saoOptionIndex].files[fileUploadedIndex].fileVersions[versionIndex] = <IFileVersion>{
          file: osduResponse.osduId,
          versionComment: osduResponse.fileComment,
          blobName: osduResponse.blobName,
          fileName: osduResponse.fileName,
          tempFileGuid: fileGuid
        };
      }
    })
  }

  sendUpsertSaoRecordRequest() {
    let saoRecordDataDto = this.getSaoRecordDataDto();

    this.updateService.postUpsertSaoRecord(saoRecordDataDto)
      .subscribe({
        next: (res) => {
          console.log('HTTP response', res);
          this.submitLoading = false;
        },
        error: (err) => {
          console.error('HTTP Error', err);
          this.submitLoading = false;
          let msg = 'Edit record failed!';
          (async () => {
            await this.dialog.openGenericDialog('Error', msg, 'Ok');
          })();
          this.changeDetectorRef.detectChanges();
        },
        complete: () => {
          console.log('HTTP request completed.');
          this.submitLoading = false;
          this.changeDetectorRef.detectChanges();
          this.store.dispatch(new RequestRecords());
          (async () => {
            await this.router.navigate(['records']);
          })();
        }
      });
  }

  getSaoRecordDataDto() {
    let saoOptions: IUpsertSaoOptionsPayload[] = [];

    this.acqOptsDataSource.data.forEach(saoOptionRecord => {

      saoOptions.push(<IUpsertSaoOptionsPayload>{
        Comment: saoOptionRecord.comment,
        OptionType: saoOptionRecord.optionType,
        OptionOsduId: saoOptionRecord.optionId,
        DataAcquisitionTime: saoOptionRecord.dataAcquisitionTime,
        AddedBy: saoOptionRecord.addedBy,
        Files: saoOptionRecord.files
      });
    });

    this.plannedOptsDataSource.data.forEach(saoOptionRecord => {

      saoOptions.push(<IUpsertSaoOptionsPayload>{
        Comment: saoOptionRecord.comment,
        OptionType: saoOptionRecord.optionType,
        OptionOsduId: saoOptionRecord.optionId,
        DataAcquisitionTime: saoOptionRecord.dataAcquisitionTime,
        AddedBy: saoOptionRecord.addedBy,
        Files: []
      });
    });

    let saoRecordDataDto =
      <IUpsertRecordPayload>{
        ExternalWellName: this.saoRecord.data.externalWellName,
        SaoOptions: saoOptions,
        ExternalPropertyId: this.saoRecord.data.externalPropertyId,
        OsduId: this.saoRecord.id,
        OsduWellId: this.saoRecord.data.wellID,
        Kind: environment.osduSurveillanceRecordKind
      }

    return saoRecordDataDto;
  }

  private errorDialog(msg: string) {
    (async () => {
      await this.dialog.openGenericDialog('Error', msg, 'Ok');
    })();
  }
}
