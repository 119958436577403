export interface ITab {
    activeLink: string;
    routerLink: string[];
    displayText: string;
  }
  
  export interface IMenuTab extends ITab {
    activeLink: string;
    routerLink: string[];
    displayText: string;
    children: ITab[];
  }
  
  export interface INavigation {
    activeLink: string;
    tabs: IMenuTab[];
    siteTitle: string;
  }