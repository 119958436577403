import { IAssignedSaoOption } from "src/app/models/osdu/sao-record.model";
import { AssignedOptionAction, AssignedOptionActions } from "../actions/assigned-sao-option-actions";

export interface IAssignedSaoOptionState {
    assignedSaoOption: IAssignedSaoOption | undefined
}

const initialState: IAssignedSaoOptionState = {
    assignedSaoOption: undefined
}

export function assignedOptionReducer(state: IAssignedSaoOptionState = initialState, action: AssignedOptionAction): IAssignedSaoOptionState {
    switch (action.type) {
        case AssignedOptionActions.PASS_ASSIGNED_OPTION: {
            state = action.payload
            return state;
        }
        case AssignedOptionActions.RESET_ASSIGNED_OPTION: {
            state = <IAssignedSaoOptionState>{ assignedSaoOption: undefined }
            return state;
        }
        default: {
            return state;
        }
    }
}