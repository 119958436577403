import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICurrency } from '../models/osdu/currency.model';
import { IPadPayload } from '../models/payloads/pad-payload.model';
import { IGeologicalFormation } from '../models/osdu/geological-formation.model';
import { IWellPayload } from '../models/payloads/well-payload.model';
import { ISaoOption } from '../models/osdu/sao-option.model';
import { ISaoRecord } from '../models/osdu/sao-record.model';
import { ITargetFormation } from '../models/osdu/target-formation.model';
import { DataManagerSearchServiceEndpoints, SaoFrontendApiEndpoints, getDataManagerEndpointUrl, getSaoFrontendApiEndpointUrl } from '../util/api-definitions.util';
import { HttpService } from './http.service';
import { IFindWellPayload } from '../models/payloads/find-well-payload.model';
import { IWellSearchQuery } from '../models/asset-development-hierarchy/well-search-query.model';
import { IAdHierarchyPath } from '../models/asset-development-hierarchy/ad-hierarchy-path.model';
import { ISurveillanceRecordsQueryModel } from '../models/surveillance-records-query-model';
import { IWellForPad } from '../models/asset-development-hierarchy/wells-for-pad-response.model';
import { IWellDetails } from '../models/well-details.model';
import { IAzureMapsSettings } from '../models/maps/azure-maps-settings.model';
import { IAzureMapsPayload } from '../models/payloads/azure-maps-payload.model';
import { IAdHierarchyAssetTeam } from '../models/asset-development-hierarchy/ad-hierarchy-asset-team.model';
import { IAdHierarchyDevArea } from '../models/asset-development-hierarchy/ad-hierarchy-dev-area.model';
import { IAdHierarchySubDevArea } from '../models/asset-development-hierarchy/ad-hierarchy-sub-dev-area.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpService: HttpService) { }

  getAzureMapsSettings(query: IAzureMapsPayload): Observable<IAzureMapsSettings> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.GET_SAO_AZURE_MAPS_SETTINGS);
    return this.httpService.httpPost<IAzureMapsSettings>(url, query);
  }

  getAllSaoRecords(query: ISurveillanceRecordsQueryModel): Observable<ISaoRecord[]> {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.ALL_SAO_RECORDS);
    return this.httpService.httpPost<ISaoRecord[]>(url, query);
  }

  getAllSaoOptions(): Observable<ISaoOption[]> {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.ALL_SAO_OPTIONS);
    return this.httpService.httpGet<ISaoOption[]>(url);
  }

  getAssetTeams(): Observable<IAdHierarchyAssetTeam[]> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.AD_HIERARCHY_ASSET_TEAMS);
    return this.httpService.httpGet<IAdHierarchyAssetTeam[]>(url);
  }

  getDevAreas(): Observable<IAdHierarchyDevArea[]> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.AD_HIERARCHY_DEV_AREAS);
    return this.httpService.httpGet<IAdHierarchyDevArea[]>(url);
  }

  getSubDevAreas(): Observable<IAdHierarchySubDevArea[]> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.AD_HIERARCHY_SUB_DEV_AREAS);
    return this.httpService.httpGet<IAdHierarchySubDevArea[]>(url);
  }

  getAllTargetFormations(): Observable<ITargetFormation[]> {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.ALL_TARGET_FORMATION);
    return this.httpService.httpGet<ITargetFormation[]>(url);
  }

  getAllGeologicalFormations(): Observable<IGeologicalFormation[]> {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.ALL_FORMATIONS);
    return this.httpService.httpGet<IGeologicalFormation[]>(url);
  }

  getAllCurrencies(): Observable<ICurrency[]> {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.ALL_CURRENCIES);
    return this.httpService.httpGet<ICurrency[]>(url);
  }

  getPadsForField(field: IPadPayload): Observable<string[]> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.PADS_FOR_FIELD);
    return this.httpService.httpPost<string[]>(url, field);
  }

  getWellsForPad(pad: IWellPayload): Observable<IWellForPad[]> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.WELLS_FOR_PAD);
    return this.httpService.httpPost<IWellForPad[]>(url, pad);
  }

  GetWellSearchSuggestions(wellSearchPhrase: IFindWellPayload): Observable<string[]> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.WELLS_SEARCH_SUGGESTIONS);
    return this.httpService.httpPost<string[]>(url, wellSearchPhrase);
  }

  GetAdHierarchyForWell(wellSearchPhrase: IWellSearchQuery): Observable<IAdHierarchyPath[]> {
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.AD_HIERARCHY_BY_WELLNAME);
    return this.httpService.httpPost<IAdHierarchyPath[]>(url, wellSearchPhrase);
  }

  GetWellDetailsList(wellIds: string[]): Observable<IWellDetails[]>{
    let url = getSaoFrontendApiEndpointUrl(SaoFrontendApiEndpoints.WELL_DETAILS);
    return this.httpService.httpPost<IWellDetails[]>(url, wellIds);
  }
}
