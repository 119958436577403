import { Component, OnInit, OnDestroy } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { Store, select } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { AppConfig } from 'src/app/services/app-config.service';
import { LoggedIn, LoggedOut, Login } from 'src/app/store/actions/login.actions';
import { IAppState } from 'src/app/store/reducers';
import { LoginState } from 'src/app/store/reducers/login.reducer';
import { IAppConfig } from '../../models/sao-appconfig.model';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  loginState$: Observable<LoginState>;
  config: IAppConfig;

  constructor(private calService: CalAngularService,
    private logger: NGXLogger,
    private store: Store<IAppState>,
  ) {
      this.loginState$ = store.pipe(select('loginState'));
      this.config = AppConfig.settings;
  }

  ngOnInit(): void {
    let isUserSignedInSub = this.calService.isUserSignedIn().subscribe((value: boolean) => {
      if(value){
        this.store.dispatch(new LoggedIn());
      } else{
        this.store.dispatch(new LoggedOut());
      }
    })
    this.subscriptions.push(isUserSignedInSub);
  }

  loginAction(){
    this.store.dispatch(new Login());
  }

  onLogin() {
    this.loginAction();
    this.calService.userInitiatedSignIn().subscribe({
      error: (error) => {
        this.logger.error('Error on attempt to sign in the user.', error)
      }
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }
}
