<div class="upload-container">
  <mat-toolbar *ngIf="currentFiles" class="progress-bar">
    <mat-progress-bar [value]="progress"></mat-progress-bar>
    <span class="progress">{{ progress }}%</span>
  </mat-toolbar>
  <mat-form-field>
    <mat-checkbox #largeFile class="checkbox-text" color="primary" (change)="largeFileChecked($event)"
      [disabled]="disabledLargeFileCheckbox">Upload a file larger than {{limitUploadLabel}}</mat-checkbox>
    <p class="files-size-warning" *ngIf="!isCheckLargeFileUpload">
      Max files size: ({{formatBytesToMb(filesSize) || '0 MiB'}}/{{limitUploadLabel}})
    </p>
    <p class="files-size-warning" *ngIf="isCheckLargeFileUpload">
      Max files size: ({{formatBytesToMb(filesSize) || '0 MiB'}}/{{largeFileLimitUploadLabel}})
    </p>
    <div class="sao-file-input">
      <mat-form-field class="files-textarea" appearance="fill">
        <textarea spellcheck="false" disabled="true" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="30" aria-label="SelectedFiles">{{fileNames}}</textarea>
      </mat-form-field>
      <input title="Select File" type="file" id="fileInput" (click)="fileInput.value = ''" (drop)="fileInput.value = ''" (change)="selectFile($event)" multiple name="fileInput" #fileInput />
    </div>
  </mat-form-field>

  <div *ngIf="message" class="message">
    {{ message }}
  </div>
  <form #fileUploadForm="ngForm" (ngSubmit)="submitFiles(fileUploadForm)">

    <mat-list>
      <div mat-subheader>Uploaded files</div>
      <mat-list-item *ngFor="let file of fileInfoUploads; let i = index;">
        <mat-icon mat-list-icon>attachment</mat-icon>
        <div mat-line><span class="file-list-file-name">{{file.name}}</span></div>
        <div mat-line><span class="file-list-file-details">Modified: {{file.lastModified | date}}</span></div>
        <div mat-line><span class="file-list-file-details">Size: {{ convertBytes(file.size) }}</span></div>
        <mat-form-field >
          <mat-label>File comment</mat-label>
          <input matInput #fileCommentInput
            [(ngModel)]="file.fileComment"
            [id]="'fileComment_' + i"
            [name]="'fileComment_' + i"
            placeholder="Please provide file version comment" required>
          <mat-error *ngIf="fileComment.hasError('required')">File Comment is required</mat-error>
        </mat-form-field>

      </mat-list-item>
    </mat-list>
    <div class="upload-buttons">
      <button type="button" id="close-button" mat-button mat-flat-button (click)="closeDialog()">
        Close
      </button>
      <button id="upload-button" mat-button mat-flat-button [disabled]="fileUploadForm.invalid || disalbledUpload" type="submit">
        Submit
      </button>
    </div>
  </form>
</div>
