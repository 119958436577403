import { Injectable } from '@angular/core';
import {Options, CsvExporterService} from 'mat-table-exporter';

@Injectable({
  providedIn: 'root'
})

export class ExportCsvReportService extends CsvExporterService {

  public override createContent(rows: Array<any>, options?: Options): any {
    return super.createContent(rows, options);
  }

  
}
