import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService } from 'src/app/services/file-upload.service';
import UnitConverter from 'src/app/util/unit-converter.util';
import { IFileUploadBlob } from 'src/app/models/files/file-upload-blob-response.model';
import { IUploadedFileDto, UploadType } from 'src/app/models/sao-option-dto.model';
import { GenericDialogService } from '../generic-dialog/generic-dialog.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { IFileInfoUpload } from 'src/app/models/files/file-info-upload';
import { UploadLargeFileService } from 'src/app/services/upload-large-file.service';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'sao-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

  filesSize: number = 0;
  limitUpload: number = 104857600; // number of bytes = 100 MiB
  limitUploadLabel: string = '100 MiB'
  largeFileLimitUpload: number = 1288490188; // number of bytes = 1.2 GiB
  largeFileLimitUploadLabel: string = '1.2 GiB'
  uploadDisalble: boolean = false;
  fileComment = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
  ]);
  disalbledUpload: boolean = true;
  disabledLargeFileCheckbox: boolean = false;
  isCheckLargeFileUpload: boolean = false;
  currenFileNameList: string[] = []
  @ViewChild('fileComment')
  fileCommentInput!: MatInput;
  form: any;
  uploadFileDtos!: IUploadedFileDto[];
  uploadType!: UploadType;


  constructor(
    public dialogRef: MatDialogRef<FileUploadComponent>,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: GenericDialogService,
    private formBuilder: FormBuilder,
    private largeFileService: UploadLargeFileService) {

    dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.filesUploaded));

    this.formBuilder = formBuilder;
    this.form = this.formBuilder.group({
      fileComment: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.uploadFileDtos = this.data.uploadFileDto;
    this.uploadType = this.data.uploadType;
  }

  ngOnDestroy(): void {
    this.largeFileService.dispose();
    this.filesUploaded = [];
  }

  placeholderText = 'Click or drag & drop a file'
  currentFiles?: File[];
  message = '';
  fileNames = this.placeholderText;
  progress = 0;
  fileInfos: File[] = [];
  fileInfoUploads: IFileInfoUpload[] = [];
  filesUploaded: IFileUploadBlob[] = [];

  async selectFile(event: any) {
    if (event?.target?.files.length > 0) {
      this.filesSize = 0;
      let files: File[] = event.target.files;
      this.currentFiles = files;
      let filesNameArray: string[] = [];
      Array.from(this.currentFiles).forEach(file => {
        filesNameArray.push(file.name);
        this.filesSize += file.size;
      });

      if (filesNameArray.length > 0) {
        this.fileNames = filesNameArray.join(',\n');
      }
    }
    else {
      this.fileNames = 'Select File';
    }

    let checkFilesize = await this.filesSizeAlert();
    if (checkFilesize) {
      this.getUploadType();
    }

    this.changeDetectorRef.detectChanges();
  }

  async filesSizeAlert(): Promise<boolean> {
    if ((this.filesSize > this.limitUpload && !this.isCheckLargeFileUpload)
      || (this.filesSize > this.largeFileLimitUpload && this.isCheckLargeFileUpload)) {
      let msg = `The files size limit has been exceeded (${this.limitUploadLabel})!`;
      if (this.isCheckLargeFileUpload) {
        msg = `The files size limit has been exceeded (${this.largeFileLimitUploadLabel})!`;
      }

      await this.dialog.openGenericDialog('Warning', msg, 'Ok');
      this.filesSize = 0;
      this.fileNames = this.placeholderText;
      this.currentFiles = undefined;
      this.currenFileNameList = [];
      this.disabledLargeFileCheckbox = false;

      return false;
    }

    return true;
  }

  async getUploadType() {
    if (this.currentFiles) {
      if (!this.isCheckLargeFileUpload) {
        this.disalbledUpload = true;
        this.disabledLargeFileCheckbox = true;
        await this.uploadFile();
      } else {
        this.disalbledUpload = true;
        this.disabledLargeFileCheckbox = true;
        await this.uploadLargeFile();

      }
    }
  }

  async uploadFile(): Promise<void> {
    if (this.currentFiles) {
      Array.from(this.currentFiles).forEach(file => {
        this.currenFileNameList.push(file.name)
      });

      let filesArray: File[] = this.currentFiles;
      this.fileUploadService.uploadFileToBlobStorage(filesArray, true).subscribe({
        next: (event: HttpEvent<any>) => {
          if (event.type == HttpEventType.UploadProgress && event.total) {
            this.progress = Math.round(100 * (event.loaded / event.total));
          }
          else if (event instanceof HttpResponse) {
            let filesUpload = event.body as IFileUploadBlob[];
            filesUpload.forEach(response => {
              this.filesUploaded.push({ ...response, fileComment: "" });
            });
            this.message = event.body.message;
          }
        },
        error: (error) => {
          console.error(error);
          this.currentFiles = undefined;
          this.disalbledUpload = false;
          this.disabledLargeFileCheckbox = false;
        },
        complete: () => {
          if (this.currentFiles != undefined) {
            let tempFileInfos = Array.from(this.fileInfos);
            this.fileInfos = tempFileInfos.concat(Array.from(this.currentFiles));
            this.fileInfoUploads = this.fileInfos.map((fileInfo) => {
              const fileInfoUploadTmp: IFileInfoUpload = Object.assign(fileInfo,
                {
                  fileComment: "",
                  externalPropertyId: ""
                });
              return fileInfoUploadTmp;
            })
            this.filesSize = 0;
            this.disalbledUpload = false;
            this.disabledLargeFileCheckbox = false;
          }
          this.currentFiles = undefined;
          this.fileNames = this.placeholderText;
          this.progress = 0;
        }
      });
    }
  }

  shouldBeDisabled() {
    return this.fileInfoUploads.some(fileInfoUpload => fileInfoUpload.fileComment === null)
  }

  submitFiles(object: any) {
    this.closePopulatedFilesUploaded();
  }

  closeDialog() {
    this.closePopulatedFilesUploaded(true);
  }

  closePopulatedFilesUploaded(isClose = false) {
    if (isClose) {
      this.filesUploaded = [];
    }

    this.filesUploaded = this.filesUploaded.map((fileInfo) => {
      let fileinfo = this.fileInfoUploads.filter(x => x.name == fileInfo.fileName)[0]

      let fileUploadBlob: IFileUploadBlob;
      if (this.uploadType === 'NewFile') {
        fileUploadBlob = <IFileUploadBlob>{
          ...fileInfo,
          fileComment: fileinfo?.fileComment,
          tempOptionGuid: this.uploadFileDtos[0].tempOptionGuid,
          tempVersionGuid: crypto.randomUUID(),
          tempFileGuid: crypto.randomUUID()
        }
      } else {
        fileUploadBlob = <IFileUploadBlob>{
          ...fileInfo,
          fileComment: fileinfo?.fileComment,
          tempOptionGuid: this.uploadFileDtos[0].tempOptionGuid,
          tempVersionGuid: this.uploadFileDtos[0].tempVersionGuid,
          tempFileGuid: crypto.randomUUID()
        }
      }

      return fileUploadBlob;
    });

    this.dialogRef.close(this.filesUploaded);
  }

  async uploadLargeFile() {
    if (this.currentFiles) {
      Array.from(this.currentFiles).forEach(file => {
        this.currenFileNameList.push(file.name)
      });

      this.largeFileService.progressEmitter.subscribe(progress => {
        this.progress = progress;
      });
      let response = await this.largeFileService.uploadLargeFileAsync(this.currentFiles[0]);
      this.filesUploaded.push({
        blobName: response.blobName,
        fileName: response.fileName,
        isUploadedToBlob: response.isUploadedToBlob,
      } as IFileUploadBlob);

      if (this.currentFiles != undefined) {
        let tempFileInfos = Array.from(this.fileInfos);
        this.fileInfos = tempFileInfos.concat(Array.from(this.currentFiles));
        this.fileInfoUploads = this.fileInfos.map((fileInfo) => {
          const fileInfoUploadTmp: IFileInfoUpload = Object.assign(fileInfo,
            {
              fileComment: "",
              externalPropertyId: ""
            });
          return fileInfoUploadTmp;
        });
        this.filesSize = 0;
        this.disalbledUpload = false;
        this.disabledLargeFileCheckbox = false;
      }
      this.currentFiles = undefined;
      this.fileNames = this.placeholderText;
      this.progress = 0;
    }
  }

  largeFileChecked(event: any) {
    this.isCheckLargeFileUpload = event.checked;

    let element = document.getElementById("fileInput");
    if (!event.checked) {
      element?.setAttribute('multiple', '');
    } else {
      element?.removeAttribute('multiple');
    }

    this.currentFiles = undefined;
    this.fileNames = this.placeholderText;
    this.filesSize = 0;
    this.progress = 0;
  }

  convertBytes(bytesNumber: number): string {
    return UnitConverter.formatBytes(bytesNumber);
  }

  formatBytesToMb(bytesNumber: number): string {
    return UnitConverter.formatBytes(bytesNumber);
  }
}
