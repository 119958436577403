import { Action } from "@ngrx/store";
import { IWellsDetailsCacheState } from "../reducers/fetch-wells-details-reducer";

export enum FetchWellsDetailsActions {
    WELLS_DETAILS_REQUEST = '[WELLS_DETAILS_CACHE] Request wells details',
    WELLS_DETAILS_REQUEST_SUCCESS = '[WELLS_DETAILS_CACHE] Wells details request - success',
    WELLS_DETAILS_REQUEST_FAILED = '[WELLS_DETAILS_CACHE] Wells details request - failed'
}

export class RequestWellsDetails implements Action {
    readonly type: string = FetchWellsDetailsActions.WELLS_DETAILS_REQUEST;
    constructor(public payload: IWellsDetailsCacheState) {}
}

export class WellsDetailsRequestSuccess implements Action {
    readonly type: string = FetchWellsDetailsActions.WELLS_DETAILS_REQUEST_SUCCESS;
    constructor(public payload: IWellsDetailsCacheState) {}
}

export class WellsDetailsRequestFailed implements Action {
    readonly type: string = FetchWellsDetailsActions.WELLS_DETAILS_REQUEST_FAILED;
    constructor(public payload: IWellsDetailsCacheState) {}
}

export type FetchWellDetailsAction
= RequestWellsDetails | WellsDetailsRequestSuccess | WellsDetailsRequestFailed;