import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FilePreviewComponent } from 'src/app/components/file-preview/file-preview.component';
import { IGenericDialogData } from 'src/app/components/generic-dialog/generic-dialog-data.model';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { GenericDialogService } from 'src/app/components/generic-dialog/generic-dialog.service';
import { IDataset } from 'src/app/models/osdu/dataset.model';
import { IAssignedSaoOptionDto, IUploadedFileDto, IUploadedFileTempDto } from 'src/app/models/sao-option-dto.model';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { IFile, IAssignedSaoOption, ISaoRecordData, OptionType } from 'src/app/models/osdu/sao-record.model';
import { UpdateService } from 'src/app/services/update.service';
import { IAppState } from 'src/app/store/reducers';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { SaoOptionRecordAddDialogComponent } from '../sao-option-record-add-dialog/sao-option-record-add-dialog.component';
import { CalAngularService } from '@cvx/cal-angular';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS, PickDateAdapter } from 'src/app/util/custom-date-formats.util';
import { SaoIngestionEndpoints, getSaoIngestionEndpointUrl } from 'src/app/util/api-definitions.util';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { EditServiceEvent } from 'src/app/services/edit-service-event.service';
import { IDeleteFileVersionsResponse } from 'src/app/models/delete-file-versions-response.model';

@Component({
  selector: 'sao-sao-options',
  templateUrl: './sao-options.component.html',
  styleUrls: ['./sao-options.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  encapsulation: ViewEncapsulation.None
})

export class SaoOptionsComponent implements OnInit, AfterViewInit, OnDestroy {

  parentForm!: FormGroup;
  assignedSaoOptionsForm!: FormGroup;

  public OT = OptionType;

  @Input() saoRecord: any;
  acqOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();
  plannedOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();

  private subscriptions: Subscription[] = [];
  allSaoOptionsData: ISaoOption[] = [];
  private saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;
  private saoOptionsLoading: boolean = false;
  optionsDropdownList: ISaoOption[] = [];
  assignedSaoOptions!: IAssignedSaoOption[];
  saoRecordData!: ISaoRecordData;
  loadingRecord: boolean = false;
  previewViewerAllowExt: string[] = ['PPT', 'PPTX', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF'];
  private userName?: string;
  maxDate: Date = new Date();
  changingRecord: boolean = false;

  assignedSaoOptionsFormArray!: FormArray;

  constructor(private matDialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    store: Store<IAppState>,
    private parent: FormGroupDirective,
    private updateService: UpdateService,
    private dialog: MatDialog,
    private editServiceEvent: EditServiceEvent,
    private genericDialog: GenericDialogService,
    private readonly calService: CalAngularService,
    private httpClient: HttpClient,) {
    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));
  }

  ngOnInit(): void {
    this.parentForm = this.parent.form;
    this.assignedSaoOptions = this.parentForm.value.assignedSaoOptions || [];
  }

  async ngAfterViewInit(): Promise<void> {
    this.getSaoOptions();
    await this.fillOptions(this.assignedSaoOptions);
    this.getUserName();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getUserName() {
    let isUserSignedInSub = this.calService.isUserSignedIn().subscribe((value: boolean) => {
      if (value) {
        let currentUserProfile = this.calService.cvxClaimsPrincipal;
        this.userName = currentUserProfile.name;
      }
    });
    this.subscriptions.push(isUserSignedInSub);
  }

  getSaoOptions() {
    let sub = this.saoOptionsCacheState$.subscribe(state => {
      this.allSaoOptionsData = state.options;
      this.saoOptionsLoading = state.optionsLoading;
      if (!this.saoOptionsLoading) {
        this.optionsDropdownList = this.allSaoOptionsData;
      }
    });
    this.subscriptions.push(sub);
  }

  async getSaoDataset(datasetIds: string[]): Promise<IDataset[]> {
    try {
      this.loadingRecord = true;
      return await new Promise<IDataset[]>((resolve, reject) => {
        this.updateService.postGetDatasets(datasetIds)
          .subscribe((datasets: IDataset[]) => {
            resolve(datasets);
            reject(() => {
              console.log("Error: getWelsForPad promise rejected!");
            });
          });
      });
    } finally {
      this.loadingRecord = false;
      console.log("Datasets retrieved with success.");
    }
  }

  addOption(event: Event, optionType: OptionType = OptionType.Planned) {
    let dialogRef = this.matDialog.open(SaoOptionRecordAddDialogComponent, { disableClose: true, height: '90vh' });

    dialogRef.afterClosed().subscribe((result: ISaoOption[]) => {
      if (result !== undefined) {
        this.addSaoOption(result, optionType);
      }
    });
  }

  openFilePreview(event: Event, fileData: any) {
    this.matDialog.open(FilePreviewComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      data: {
        file: fileData
      }
    });
  }

  addSaoOption(saoOptionsToAdd: ISaoOption[], optionType: OptionType = OptionType.Planned) {
    let alreadyAddedPlannedOptions: string[] = [];

    [alreadyAddedPlannedOptions, saoOptionsToAdd]
      = this.FindAlreadyAddedSaoOptionsAndRemoveThemFromTheListToAdd(saoOptionsToAdd);

    let assignedSaoOptions = this.AssignSaoOptions(saoOptionsToAdd, optionType);

    this.setAssignedSaoOptionsTablesDataSources(assignedSaoOptions, optionType);

    this.parentForm.patchValue({
      assignedSaoOptions: this.acqOptsDataSource.data.concat(this.plannedOptsDataSource.data)
    });

    this.changeDetectorRef.detectChanges();

    if (alreadyAddedPlannedOptions.length > 0) {
      this.openInfoDialog(alreadyAddedPlannedOptions)
    }
  }

  private AssignSaoOptions(saoOptionsToAdd: ISaoOption[], optionType: OptionType): IAssignedSaoOptionDto[] {
    let assignedSaoOptions: IAssignedSaoOptionDto[] =
      optionType == OptionType.Acquired ?
        this.acqOptsDataSource.data : this.plannedOptsDataSource.data;

    saoOptionsToAdd.forEach(saoOptionToAdd => {
      this.allSaoOptionsData.forEach(localSaoOptionData => {
        if (localSaoOptionData.id == saoOptionToAdd.id) {
          assignedSaoOptions.push({
            optionName: localSaoOptionData.data.saoOptionName,
            optionId: localSaoOptionData.id,
            optionType: optionType,
            comment: localSaoOptionData.data.comment || '',
            addedBy: this.userName ?? '',
            dataAcquisitionTime: new Date().toJSON().slice(0, 10),
            files: [] as IUploadedFileDto[],
            tempOptionGuid: crypto.randomUUID()
          } as IAssignedSaoOptionDto);
        }
      });
    });

    return assignedSaoOptions;
  }

  private FindAlreadyAddedSaoOptionsAndRemoveThemFromTheListToAdd(saoOptionsToAdd: ISaoOption[]): [string[], ISaoOption[]] {
    let allAssignedSaoOptions: IAssignedSaoOptionDto[] = this.plannedOptsDataSource.data;
    let alreadyAddedPlannedOptions: string[] = [];

    allAssignedSaoOptions.forEach(assignedOption => {
      saoOptionsToAdd.forEach((optionToAdd, index) => {
        if (assignedOption.optionId === optionToAdd.id) {
          if (assignedOption.optionType === OptionType.Planned) {
            alreadyAddedPlannedOptions.push(assignedOption.optionName);
          }
          saoOptionsToAdd.splice(index, 1);
        }
      });
    });

    return [alreadyAddedPlannedOptions, saoOptionsToAdd]
  }

  private setAssignedSaoOptionsTablesDataSources(assignedSaoOptions: IAssignedSaoOptionDto[], optionType: OptionType) {
    if (optionType == OptionType.Planned) {
      this.plannedOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(assignedSaoOptions);
    } else {
      this.acqOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(assignedSaoOptions);
    }
  }

  openInfoDialog(optionsPlanned: string[]) {
    let msg = this.getMessageForPlannedOpts(optionsPlanned);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '210px';
    dialogConfig.width = '500px';
    dialogConfig.data = <IGenericDialogData>{
      dialogType: 'Warning',
      content: `${msg}`,
      buttons: 'Ok'
    };

    this.dialog.open(GenericDialogComponent, dialogConfig);
  }

  private getMessageForPlannedOpts(notAddedOptions: string[]): string {
    let msg = '';
    let options: string = notAddedOptions.map(el => `"${el}"`).join(', ');
    if (notAddedOptions.length > 1) {
      msg = `SA&O activities ${options} have not been added - they are already planned for this well.`
    } else {
      msg = `SA&O activity ${options} has not been added - it is already planned for this well.`
    }
    return msg;
  }

  async fillOptions(data: IAssignedSaoOption[]) {

    let acquiredAssignedSaoOptions: IAssignedSaoOptionDto[] = [];
    let plannedAssignedSaoOptions: IAssignedSaoOptionDto[] = [];

    this.fillAssignedSaoOptions(data, acquiredAssignedSaoOptions, plannedAssignedSaoOptions);

    this.acqOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(acquiredAssignedSaoOptions);
    this.plannedOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(plannedAssignedSaoOptions);

    this.changeDetectorRef.detectChanges();
  }

  fillAssignedSaoOptions(dataAssignedSaoOptions: IAssignedSaoOption[],
    acquiredAssignedSaoOptions: IAssignedSaoOptionDto[],
    plannedAssignedSaoOptions: IAssignedSaoOptionDto[]) {
    if (dataAssignedSaoOptions) {
      dataAssignedSaoOptions.forEach(opt => {
        if (opt.optionType == OptionType.Acquired) {
          this.pushToSaoOptions(opt, opt.optionType, acquiredAssignedSaoOptions);
        }
        else if (opt.optionType == OptionType.Planned) {
          this.pushToSaoOptions(opt, opt.optionType, plannedAssignedSaoOptions);
        }
      });

    }
  }

  pushToSaoOptions(opt: IAssignedSaoOption, optionType: OptionType, assignedDtoOpts: IAssignedSaoOptionDto[]) {
    let saoOptionTemp = this.allSaoOptionsData.find(option => option.id == opt.optionId) ?? {} as ISaoOption;

    let files: IUploadedFileDto[] = [];
    let optionGuid = opt?.tempOptionGuid !== undefined && opt?.tempOptionGuid !== null ? opt.tempOptionGuid : crypto.randomUUID();

    if (optionType === OptionType.Acquired) {
      opt.files.forEach(file => {
        let versionGuid = crypto.randomUUID();
        let fileData = <IUploadedFileDto>{
          fileName: file.fileName,
          fileVersions: file.fileVersions.map(x => {
            return {
              ...x,
              tempFileGuid: crypto.randomUUID()
            }
          }),
          tempOptionGuid: optionGuid,
          tempVersionGuid: versionGuid
        };
        files.push(fileData);
      })
    };

    let assignedOpt = <IAssignedSaoOptionDto>{
      comment: opt.comment,
      optionId: opt.optionId,
      optionName: saoOptionTemp.data.saoOptionName,
      optionType: optionType,
      addedBy: opt.addedBy,
      dataAcquisitionTime: opt.dataAcquisitionTime,
      files: files,
      expanded: false,
      tempOptionGuid: optionGuid
    };

    assignedDtoOpts.push(assignedOpt);
  }

  checkFileExtension(fileType: string): boolean {
    if (this.previewViewerAllowExt.includes(fileType.toUpperCase())) {
      return true;
    }
    return false;
  }

  async moveOptionToAcquired(row: IAssignedSaoOptionDto) {
    let assignedSaoOptions: IAssignedSaoOption[] = this.parentForm.value.assignedSaoOptions;

    this.removeOption(row, OptionType.Planned, false);
    this.addOptionRow(row, assignedSaoOptions);
  }

  addOptionRow(row: IAssignedSaoOptionDto, assignedSaoOptions: IAssignedSaoOption[]) {
    let optionToAdd = this.allSaoOptionsData.find(localSaoOptionsData => localSaoOptionsData.id == row.optionId);
    if (optionToAdd == null) {
      console.warn(`Option: ${row.optionName} | Identifier: ${row.optionId} | Not found in the list of all SaoOptions`);
      return;
    }

    let optionGuid = row?.tempOptionGuid !== undefined && row?.tempOptionGuid !== null ? row.tempOptionGuid : crypto.randomUUID()

    let acqOptions = this.acqOptsDataSource.data;
    acqOptions.push(<IAssignedSaoOptionDto>{
      optionName: row.optionName,
      optionId: row.optionId,
      optionType: OptionType.Acquired,
      comment: row.comment,
      files: [],
      addedBy: row.addedBy,
      dataAcquisitionTime: row.dataAcquisitionTime,
      expanded: false,
      tempOptionGuid: optionGuid
    });

    this.acqOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(acqOptions);

    assignedSaoOptions.push(<IAssignedSaoOption><unknown>{
      comment: row.comment,
      files: {} as unknown as IFile[],
      index: "",
      optionId: row.optionId,
      optionType: OptionType.Acquired,
      tempOptionGuid: optionGuid
    });

    this.parentForm.patchValue({
      assignedSaoOptions: assignedSaoOptions
    });

    this.changeDetectorRef.detectChanges();
  }

  async removeOption(row: IAssignedSaoOptionDto, optionType: OptionType = OptionType.Planned, removeFlag: boolean = true) {
    let userResponse = false;
    let idsList: string[] = [];
    let cacheAcquired: IAssignedSaoOptionDto[] = this.acqOptsDataSource.data;

    if (removeFlag) {
      let msg = '';
      if (optionType === OptionType.Planned) {
        msg = 'You are about to remove the planned SA&O activity. Continue?';
      } else {
        msg = 'You are about to remove the SA&O activity along with all submitted comments and attachments. Continue?';
      }
      userResponse = await this.genericDialog.openGenericDialog('Warning', msg, 'Cancel,Ok');
    }

    if (userResponse || removeFlag === false) {
      if (optionType === OptionType.Planned) {
        let optionTypesLocal = this.plannedOptsDataSource.data;
        let optionTypesLocalWithRemovedRecord = this.removeItemWithSlice(optionTypesLocal, optionTypesLocal.findIndex(x => x.tempOptionGuid == row.tempOptionGuid));
        this.plannedOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(optionTypesLocalWithRemovedRecord);
      }
      else {
        let optionTypesLocal = this.acqOptsDataSource.data;
        idsList = this.getFileIdsToRemove(row, optionType);
        let optionTypesLocalWithRemovedRecord = this.removeItemWithSlice(optionTypesLocal, optionTypesLocal.findIndex(x => x.tempOptionGuid == row.tempOptionGuid));
        this.acqOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(optionTypesLocalWithRemovedRecord);
      }

      let assignedSaoOptions: IAssignedSaoOption[] = this.parentForm.value.assignedSaoOptions;
      let filteredAssignedSaoOptions = assignedSaoOptions.filter(assignedSaoOption =>
        assignedSaoOption?.tempOptionGuid !== row?.tempOptionGuid);

      this.parentForm.patchValue({
        assignedSaoOptions: filteredAssignedSaoOptions
      });
    }

    this.deleteFileVersions(userResponse, idsList, cacheAcquired, optionType, removeFlag);

    this.changeDetectorRef.detectChanges();
  }

  private getFileIdsToRemove(assignedSaoOption: IAssignedSaoOptionDto, optionType: OptionType): string[] {
    let idsList: string[] = [];
    if (optionType === OptionType.Acquired) {
      let acquired = this.acqOptsDataSource.data;
      let optionWithFiles = acquired.find(option => option.tempOptionGuid === assignedSaoOption.tempOptionGuid)?.files
        .filter(file => file.fileVersions.length > 0);
      optionWithFiles?.forEach(file => {
        file.fileVersions.forEach(fileVer => {
          if (fileVer?.file !== undefined && fileVer?.file !== null && fileVer.file !== '') {
            idsList.push(fileVer.file);
          }
        });
      });
    }
    return idsList;
  }

  private deleteFileVersions(userResponse: boolean, idsList: string[], cacheAcquired: IAssignedSaoOptionDto[], optionType: OptionType, removeFlag: boolean) {
    if (userResponse && optionType === OptionType.Acquired && idsList.length > 0 && removeFlag === true) {
      this.changingRecord = true;
      let eventBody: IDeleteFileVersionsResponse = <IDeleteFileVersionsResponse>{};
      let payload = idsList;
      let url = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.DELETE_FILE_VERSIONS);
      let request = new HttpRequest('POST', url, payload, {
        reportProgress: false
      });

      this.httpClient.request(request).subscribe({
        next: (event: any) => {
          if (event?.body && JSON.stringify(event.body).includes('removedFileVersions')) {
            eventBody = JSON.parse(JSON.stringify(event.body)) as IDeleteFileVersionsResponse;
            console.log(`Deleted file(s): ${eventBody.removedFileVersions?.join(', ')}`);
          }
        },
        error: (error: any) => {
          console.error(error);
          let msg = 'Option deletion failed!';
          this.changingRecord = false;
          (async () => {
            await this.genericDialog.openGenericDialog('Error', msg, 'Ok');
          })();
          this.acqOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(cacheAcquired);
          this.parentForm.patchValue({
            assignedSaoOptions: this.acqOptsDataSource.data.concat(this.plannedOptsDataSource.data)
          });
          this.changeDetectorRef.detectChanges();
        },
        complete: () => {
          console.log(`Completed deletion of the file(s): ${eventBody.removedFileVersions?.join(', ')}`);
          this.changingRecord = false;
          this.editServiceEvent.editRecord(userResponse);
        }
      });
    }
  }

  updateAssignedSaoOptionsEventEmit(uploadedFileTepmDtos: IUploadedFileTempDto) {

    if (uploadedFileTepmDtos?.uploadedFileDtos
       && uploadedFileTepmDtos.uploadedFileDtos.length > 0 && uploadedFileTepmDtos.tempOptionGuid === null) {
      let indexSaoOption: number = this.acqOptsDataSource.data.findIndex(x => x.tempOptionGuid === uploadedFileTepmDtos.uploadedFileDtos[0].tempOptionGuid);
      this.acqOptsDataSource.data[indexSaoOption].files = uploadedFileTepmDtos.uploadedFileDtos;
    } else if(uploadedFileTepmDtos?.tempOptionGuid) {
      let indexSaoOption: number = this.acqOptsDataSource.data.findIndex(x => x.tempOptionGuid === uploadedFileTepmDtos.tempOptionGuid);
      this.acqOptsDataSource.data[indexSaoOption].files = uploadedFileTepmDtos.uploadedFileDtos;
    }

    this.parentForm.patchValue({
      assignedSaoOptions: this.acqOptsDataSource.data.concat(this.plannedOptsDataSource.data)
    });
  }

  updateSaoOptionProperty = (tempOptionGuid: string, comment: string) => {
    let index = this.acqOptsDataSource.data.findIndex(x => x.tempOptionGuid == tempOptionGuid);
    this.acqOptsDataSource.data[index].comment = comment;
    this.parentForm.patchValue({
      assignedSaoOptions: this.acqOptsDataSource.data.concat(this.plannedOptsDataSource.data)
    });
  }

  updateAcquisitionDate(row: IAssignedSaoOptionDto, deteAcquisition: string) {
    let index = this.acqOptsDataSource.data.findIndex(x => x.optionId === row.optionId
      && x.dataAcquisitionTime === row.dataAcquisitionTime && x.addedBy === row.addedBy
      && x.tempOptionGuid === row.tempOptionGuid);
    this.acqOptsDataSource.data[index].dataAcquisitionTime = deteAcquisition;

    this.parentForm.patchValue({
      assignedSaoOptions: this.acqOptsDataSource.data.concat(this.plannedOptsDataSource.data)
    });
  }

  checkSubset = (parentArray: any[], subsetArray: any[]) => {
    return subsetArray.every((el) => {
      return parentArray.includes(el)
    });
  }

  removeItemWithSlice(arr: any[], index: number) {
    const firstArr = arr.slice(0, index);
    const secondArr = arr.slice(index + 1);
    return [...firstArr, ...secondArr];
  }

  plannedOptColumns = [
    {
      columnDef: "saoOptioName",
      header: "Planned SA&O",
      cell: (option: IAssignedSaoOptionDto) => `${option.optionName}`
    }
  ];

  displayedPlannedOptCols = this.plannedOptColumns.map(c => c.columnDef);

  acqOptionsColumns = [
    {
      columnDef: "saoOptioName",
      header: "Acquired SA&O",
      cell: (option: IAssignedSaoOptionDto) => `${option.optionName}`
    }
  ];
  displayedAcqOptCols = this.acqOptionsColumns.map(c => c.columnDef);
}
