import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUpsertRecordPayload } from '../models/payloads/upsert-record-payload.model';
import { IDataset } from '../models/osdu/dataset.model';
import { ISaoRecordDataDTo } from '../models/sao-record-dto.model';
import { DataManagerSearchServiceEndpoints, getDataManagerEndpointUrl, getSaoIngestionEndpointUrl, SaoIngestionEndpoints } from '../util/api-definitions.util';
import { HttpService } from './http.service';
import { IFileInfoResponse } from '../models/files/file-info-upload';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(private httpService: HttpService) { }

  postGetDatasets(osduFileIds: string[]): Observable<IDataset[]> {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.DATASETS_BY_IDS);
    return this.httpService.httpPost<IDataset[]>(url, osduFileIds);
  }

  postGetDatasetDetails(osduFileIds: string[]): Observable<IFileInfoResponse[]> {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.DATASETS_DETAILS_BY_IDS);
    return this.httpService.httpPost<IFileInfoResponse[]>(url, osduFileIds);
  }

  postUpsertSaoRecord(saoRecord: IUpsertRecordPayload) {
    let url = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.INGEST_SURVEILLANCE_RECORD);
    return this.httpService.httpPost<ISaoRecordDataDTo>(url, saoRecord);
  }
}
