import { Injectable, OnDestroy } from "@angular/core";
import { ISaoRecordTableDataSource } from "../models/sao-record-search-table.model";
import { IAppState } from "../store/reducers";
import { BehaviorSubject, Observable, Subscription, switchMap } from "rxjs";
import { Store, select } from "@ngrx/store";
import { ISaoRecord } from "../models/osdu/sao-record.model";
import { DatePipe } from "@angular/common";

export interface ISaoRecordDataSource {
  records: ISaoRecordTableDataSource[];
  loading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SaoRecordsDataSourceService implements OnDestroy {

  private saoRecordsDataSourceSubject: BehaviorSubject<ISaoRecordDataSource>
    = new BehaviorSubject<ISaoRecordDataSource>(<ISaoRecordDataSource>{
      records: [],
      loading: true
    });

  private subscriptions: Subscription[] = [];
  private saoRecords: ISaoRecord[] = [];
  private saoRecordsLoading: boolean = true;

  constructor(private datePipe: DatePipe, private store: Store<IAppState>) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getSaoRecordDataSource(): Observable<ISaoRecordDataSource> {
    this.getSaoRecordsDataSource();
    return this.saoRecordsDataSourceSubject.asObservable();
  }

  private formatDate(date: string | undefined): string | null {
    return date !== null ? this.datePipe.transform(`${date ?? new Date(0)}`, 'yyyy-MM-dd') : '';
  }

  getSaoRecordsDataSource(): void {
    let saoRecordsCacheState$ = this.store.pipe(select('saoRecordsCacheState'));
    let wellsDetailsCacheState$ = this.store.pipe(select('wellsDetailsCacheState'));

    let sub = saoRecordsCacheState$.pipe(
      switchMap(saoRecordsState => {
        this.saoRecordsLoading = saoRecordsState.recordsLoading;
        if (!saoRecordsState.recordsLoading) {
          this.saoRecords = saoRecordsState.records;
        }
        return wellsDetailsCacheState$;
      })
    ).subscribe({
      next: (wellDetailsState) => {
        let saoRecordDataSourceLoading = !(!this.saoRecordsLoading && !wellDetailsState.wellsDetailsLoading);
        if (!wellDetailsState.wellsDetailsLoading) {
          let recordsDataSource = this.saoRecords.map(record => {
            let wellDetail = wellDetailsState.wellsDetailsList.find(wellDetail => wellDetail.mudiPropertyId === record.data.externalPropertyId);
            return <ISaoRecordTableDataSource>{
              ...record,
              data: {
                ...record.data,
                assetTeam: wellDetail?.assetTeam,
                developmentArea: wellDetail?.devArea,
                subDevelopmentArea: wellDetail?.subDevArea,
                padName: wellDetail?.padName,
                permittedLegalWellName: wellDetail?.permittedLegalWellName,
                apiNumber: wellDetail?.api14,
                apiPropertyId: wellDetail?.api14 ?? wellDetail?.mudiPropertyId,
                preferredWellName: wellDetail?.permittedLegalWellName ?? wellDetail?.mudiWellName,
                externalPropertyId: wellDetail?.mudiPropertyId,
                fracStartDate: this.formatDate(wellDetail?.fracStartDate),
                targetFormation: wellDetail?.targetFormation?.split(' ')[0],
                spudDate:  this.formatDate(wellDetail?.spudDate),
                forecastPopDate: this.formatDate(wellDetail?.forecastedPop),
                wellLongitude: wellDetail?.longitudeFt,
                wellLatitude: wellDetail?.latitudeFt
              }
            }
          });
          this.saoRecordsDataSourceSubject.next(<ISaoRecordDataSource>{
            records: recordsDataSource,
            loading: saoRecordDataSourceLoading
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('SAO Records Data Source fetch completed');
      }
    });

    this.subscriptions.push(sub);
  }

}
