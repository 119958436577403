import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DataService } from "src/app/services/data.service";
import { IAppState } from "../reducers";
import { Store } from "@ngrx/store";
import { FetchWellDetailsAction, FetchWellsDetailsActions, WellsDetailsRequestFailed, WellsDetailsRequestSuccess } from "../actions/fetch-wells-details.action";
import { catchError, exhaustMap, map } from "rxjs";
import { IWellsDetailsCacheState } from "../reducers/fetch-wells-details-reducer";

@Injectable()
export class FetchWellDetailsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) {}

        public fetchWellDetals$ = createEffect(() => {
            return this.actions$.pipe(
                ofType<FetchWellDetailsAction>(FetchWellsDetailsActions.WELLS_DETAILS_REQUEST),
                map(action => action.payload),
                exhaustMap((payload) => {
                    return this.dataService.GetWellDetailsList(payload.wellsIds).pipe(
                        map(wellDetailsResult => {
                            return new WellsDetailsRequestSuccess(<IWellsDetailsCacheState>{
                                wellsDetailsList: wellDetailsResult,
                                wellsIds: payload.wellsIds,
                                wellsDetailsLoading: false,
                                wellDetailsRequestError: null
                            });
                        }),
                        catchError((err: Error) => {
                            let error = err.message;
                            this.store.dispatch(new WellsDetailsRequestFailed(<IWellsDetailsCacheState>{
                                wellsDetailsList: [],
                                wellsIds: [],
                                wellsDetailsLoading: false,
                                wellDetailsRequestError: error
                            }));
                            throw err;
                        })
                    )
                })
            )
        });
}
