import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IFileUploadBlob } from '../models/files/file-upload-blob-response.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private uploadedFiles = new BehaviorSubject<any>(null);

  setUploadedFiles(fileUploadResult: IFileUploadBlob[]) {
    this.uploadedFiles.next(fileUploadResult);
  }

  getUploadedFiles() {
    return this.uploadedFiles.asObservable();
  }

}
