import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as echarts from 'echarts';
import { Observable, Subscription, debounceTime } from 'rxjs';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { IAppState } from 'src/app/store/reducers';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { ChartsUtils } from 'src/app/util/charts.util';
import { IDashboardFilterSaoRecordDataSource } from '../../filter/filter.component';
import { OptionType } from 'src/app/models/osdu/sao-record.model';
import { ISaoFlattenedRecordDataSource } from 'src/app/models/sao-record-flat-table.model';

type EChartsOption = echarts.EChartsOption;

@Component({
  selector: 'sao-chart',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {

  @Input()
  recordsState!: Observable<IDashboardFilterSaoRecordDataSource>;
  @Output()
  reloadMap: EventEmitter<boolean> = new EventEmitter<boolean>();

  private saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;

  records: ISaoFlattenedRecordDataSource[] = [];
  private allOptions: ISaoOption[] = [];
  private subscriptions: Subscription[] = [];
  hide: boolean = false;
  chevronType: string = 'expand_less';
  showHideBtnText: string = 'Hide pie chart';
  pieChart!: echarts.ECharts;
  stackedBarChart!: echarts.ECharts;

  constructor(store: Store<IAppState>,
              private sessionStorageService: SessionStorageService){
    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));
  }

  ngOnInit(): void {
    this.getAndProcessData();
  }

  private getAndProcessData() {
    this.initializeSimplePieChart();
    this.initializeStackedBarChart();
    this.getOptions();
    this.getSaoRecords();
  }

  private initializeSimplePieChart() {
    let chartDom = document.getElementById('simple-pie-chart')!;
    this.pieChart = echarts.init(chartDom);
  }

  private initializeStackedBarChart() {
    let chartDom = document.getElementById('stacked-bar-chart')!;
    this.stackedBarChart = echarts.init(chartDom);
  }

  private getOptions() {
    let sub = this.saoOptionsCacheState$.subscribe({
      next: (optionsState) => {
        if (!optionsState.optionsLoading) {
          let optionsSorted = [...optionsState.options].sort((a, b) => (a.data.saoOptionName < b.data.saoOptionName) ? -1 : 1);
          this.allOptions = optionsSorted;
        }
      }
    })
    this.pushSubscription(sub);
  }

  private getSaoRecords() {
    let sub =  this.recordsState.pipe(debounceTime(500)).subscribe({
      next: (recordsState) => {
        if (!recordsState.loading) {
          this.records = recordsState.filteringResult.filteredFlattenedRecords;
          let filteredRecordsFlatArray = this.getSaoActivitiesOccurencesList();
          this.SetStackedBarChartData(filteredRecordsFlatArray);
          this.SetSimplePieChartData(filteredRecordsFlatArray);
        } else {
          this.records = recordsState.filteringResult.filteredFlattenedRecords;
        }
      }
    })
    this.pushSubscription(sub);
  }

  private SetSimplePieChartData(filteredRecordsFlatArray: IOptionOccurence[]) {
    let option: EChartsOption =
      new ChartsUtils().getSimplePieChartOption(filteredRecordsFlatArray);
    this.pieChart.clear();
    this.pieChart.setOption(option);
  }

  private SetStackedBarChartData(filteredRecordsFlatArray: IOptionOccurence[]) {
    let option: EChartsOption =
      new ChartsUtils().getHorizontalStackedChartOption(filteredRecordsFlatArray);
    this.stackedBarChart.clear();
    this.stackedBarChart.setOption(option);
  }

  private getSaoActivitiesOccurencesList(): IOptionOccurence[]{
    let optionsOccurences: IOptionOccurence[] = [];
    this.records.forEach(record => {
      optionsOccurences.push({
        optionType: record.data.activityType,
        optionName: record.data.activityName ?? "",
        devAreaName: record.data.developmentArea,
        tgtFormation: record.data.targetFormation
      });
    })

    optionsOccurences = this.applyOptionsFilterOnOptionsData(optionsOccurences);
    return this.applyActivityTypeFilterOnOptionsData(optionsOccurences);
  }

  private applyOptionsFilterOnOptionsData(optionsOccurences: IOptionOccurence[]): IOptionOccurence[] {
    let filter = this.sessionStorageService.getFilterStateFromSessionStorage();
    let optionsFilter = filter.saoOptions.map(o => o.data.saoOptionName);

    if (optionsFilter.length > 0) {
      let filteredOccurences: IOptionOccurence[] = optionsOccurences.filter(option => optionsFilter.indexOf(option.optionName) > -1);
      return filteredOccurences;
    } else {
      return optionsOccurences;
    }
  }

  private applyActivityTypeFilterOnOptionsData(optionsOccurences: IOptionOccurence[]): IOptionOccurence[] {
    let filter = this.sessionStorageService.getFilterStateFromSessionStorage();
    let optionTypeString = filter.activityType;

    if(optionTypeString !== undefined && optionTypeString !== ''){
      let filteredOccurences: IOptionOccurence[] = optionsOccurences.filter(option => option.optionType.toString() === optionTypeString);
      return filteredOccurences;
    } else {
      return optionsOccurences;
    }
  }

  private pushSubscription(subscription: Subscription){
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  showHidePieChart() {
    this.hide = !this.hide;
    if (this.hide) {
      this.chevronType = 'expand_more';
      this.showHideBtnText = 'Show pie chart';
    } else {
      this.chevronType = 'expand_less';
      this.showHideBtnText = 'Hide pie chart';
    }
    this.reloadMap.emit(true);
  }
}

export interface IOptionOccurence {
  optionType: OptionType,
  devAreaName: string,
  optionName: string,
  tgtFormation: string,
}
