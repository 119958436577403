import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatestWith, debounceTime, Observable, of, Subject, Subscription } from 'rxjs';
import { IBasin } from 'src/app/models/osdu/basin.model';
import { IPadPayload } from 'src/app/models/payloads/pad-payload.model';
import { IField } from 'src/app/models/osdu/field.model';
import { IWellPayload } from 'src/app/models/payloads/well-payload.model';
import { ISaoOption, ISaoOptionData } from 'src/app/models/osdu/sao-option.model';
import { IFileVersion, ISaoRecord } from 'src/app/models/osdu/sao-record.model';
import { DataService } from 'src/app/services/data.service';
import { UpdateService } from 'src/app/services/update.service';
import { IAppState } from 'src/app/store/reducers';
import { IFieldsCacheState } from 'src/app/store/reducers/fetch-fields.reducer';
import { IFindWellPayload, SearchType, SearchTypes, SearchTypesLabels } from 'src/app/models/payloads/find-well-payload.model';
import { IWellSearchQuery } from 'src/app/models/asset-development-hierarchy/well-search-query.model';
import { IAdHierarchyPath } from 'src/app/models/asset-development-hierarchy/ad-hierarchy-path.model';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { SaoOptionsComponent } from '../shared/sao-options/sao-options.component';
import { IAssignedSaoOptionDto, IUploadedFileDto } from 'src/app/models/sao-option-dto.model';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { IFileUploadOsduResponse } from 'src/app/models/files/file-upload-osdu-response.model';
import { IUpsertRecordPayload, IUpsertSaoOptionsPayload } from 'src/app/models/payloads/upsert-record-payload.model';
import { RequestRecords } from 'src/app/store/actions/fetch-records.actions';
import { environment } from 'src/environments/environment';
import { IWellForPad } from 'src/app/models/asset-development-hierarchy/wells-for-pad-response.model';
import { SaoRecordService } from 'src/app/services/sao-record.service';
import { IGenericDialogData } from '../../generic-dialog/generic-dialog-data.model';
import { GenericDialogComponent } from '../../generic-dialog/generic-dialog.component';
import { AddRecordService } from './add-record.service';
import { GenericDialogService } from '../../generic-dialog/generic-dialog.service';
import { ISubFieldsCacheState } from 'src/app/store/reducers/fetch-sub-fields.reducer';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { ISaoRecordsCacheState } from 'src/app/store/reducers/fetch-records.reducer';
import { IBasinsCacheState } from 'src/app/store/reducers/fetch-basins.reducer';

@Component({
  selector: 'sao-add-records',
  templateUrl: './add-records.component.html',
  styleUrls: ['./add-records.component.scss']
})
export class AddRecordsComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  private subForPadsAndWells: Subscription[] = [];
  private basinsLoading: boolean = false;
  private fieldsLoading: boolean = false;
  submitLoading: boolean = false;
  visibleFormWarning: any = {
    assetTeam: false,
    devArea: false,
    subDevelopmentArea: false,
    padName: false,
    wellName: false,
    assignedSaoOptions: false
  };
  component: any;
  searchTypes: string[] = Object.values(SearchTypes);

  constructor(private router: Router,
    private updateService: UpdateService,
    private matDialog: MatDialog,
    private store: Store<IAppState>,
    private saoRecordService: SaoRecordService,
    private service: DataService,
    private fileUploadService: FileUploadService,
    private cd: ChangeDetectorRef,
    private addRecordService: AddRecordService,
    private dialog: GenericDialogService) {
    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));
    this.basinsCacheState$ = store.pipe(select('basinsCacheState'));
    this.fieldsCacheState$ = store.pipe(select('fieldsCacheState'));
    this.subFieldsCacheState$ = store.pipe(select('subFieldsCacheState'));
    this.saoRecordsCacheState$ = store.pipe(select('saoRecordsCacheState'));
  }

  acqOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();
  plannedOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();

  fieldForPads: IPadPayload = <IPadPayload>{ SubFieldName: null };
  padForWells: IWellPayload = <IWellPayload>{ PadName: null };
  private wellFindPayload: IFindWellPayload = <IFindWellPayload>{ SearchPhrase: null, SearchType: SearchTypes.PAD };

  searchTypeTxt: string = SearchTypesLabels.PAD_NAME;
  searchPlaceholderTxt: string = this.setSearchPlaceholderTxt(this.searchTypeTxt.toString());

  allSaoRecords: ISaoRecord[] = [];
  private saoRecordsCacheState$: Observable<ISaoRecordsCacheState>;

  allSaoOptionsData: ISaoOption[] = [];
  private saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;

  basinRecords: IBasin[] = [];
  private basinsCacheState$: Observable<IBasinsCacheState>;

  //field == dev area
  fieldOsduRecords: IField[] = [];
  private fieldsCacheState$: Observable<IFieldsCacheState>;
  subFieldsCacheState$: Observable<ISubFieldsCacheState>;
  devAreaDropdownList: IField[] = [];

  //sub Field == sub dev area
  subFieldOsduRecords: IField[] = [];
  subDevAreaDropdownList: IField[] = [];
  subFields = new FormControl();

  padsDropdownList: string[] = [];
  padsLoading: boolean = false;

  wellsDropdownList: IWellForPad[] = [];
  wellsLoading: boolean = false;

  options = new FormControl();
  optionsDropdownList: ISaoOption[] = [];

  wellListSuggestion: string[] = [];
  wellsFindLoading: boolean = false;
  wellListDebounce$: Observable<string[]> = of([]);
  subject: Subject<string> = new Subject();
  mudiRecords: IAdHierarchyPath[] = [];
  mudiWellFind: IWellSearchQuery = <IWellSearchQuery>{ SearchTypeName: null, SearchType: SearchTypes.PAD };

  addRecordForm: FormGroup = new FormGroup({
    assignedSaoOptions: new FormControl('', Validators.required),
    assetTeam: new FormControl('', Validators.required),
    devArea: new FormControl('', Validators.required),
    subDevelopmentArea: new FormControl('', Validators.required),
    padName: new FormControl('', Validators.required),
    wellName: new FormControl('', Validators.required),
    recordByWell: new FormControl('')
  });

  @ViewChild("assetTeamDropdown") assetTeamDropdownControl!: MatSelect;
  @ViewChild("devAreaDropdown") devAreaDropdownControl!: MatSelect;
  @ViewChild("subDevAreaDropdown") subDevAreaDropdownControl!: MatSelect;
  @ViewChild("padDropdown") padDropdownControl!: MatSelect;
  @ViewChild("wellDropdown") wellDropdownControl!: MatSelect;
  @ViewChild("recordByWellAutocomplete") recordByWellAutocompleteControl!: MatAutocomplete;
  @ViewChild("saoOptionsComponent") saoOptionsComponentControl!: SaoOptionsComponent;

  isDataLoading(): boolean {
    return (this.basinsLoading
      || this.fieldsLoading);
  }

  async ngOnInit() {
    this.saoRecordService.getBasins(this);
    this.saoRecordService.getSaoRecords(this);
    this.getFields();
  }

  ngAfterViewInit() {
    this.onInitDiableDropdownSelection();
    this.subject.pipe(debounceTime(500)).subscribe((searchQuery: string) => {
      if (searchQuery.length > 2) {
        this.wellsFindLoading = true;
      }
      this.wellFindPayload.SearchPhrase = searchQuery;
      this.getWellSuggestion(this.wellFindPayload)
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getFields() {
    let sub = this.fieldsCacheState$.pipe(
      combineLatestWith(this.subFieldsCacheState$)
    )
      .subscribe(([fieldsCacheContent, subFieldsCacheContent]) => {
        this.fieldsLoading = fieldsCacheContent.fieldsLoading && subFieldsCacheContent.subFieldsLoading;
        this.fieldOsduRecords = [...fieldsCacheContent.fields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
        this.subFieldOsduRecords = [...subFieldsCacheContent.subFields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
      });

    this.subscriptions.push(sub);
  }

  getPads(field: IPadPayload) {
    if (field.SubFieldName !== null) {
      this.padsLoading = true;
      let sub = this.service.getPadsForField(field)
        .subscribe({
          next: (response: string[]) => {
            this.padsDropdownList = response;
          },
          error: (error) => {
            this.padsLoading = false;
            console.error('HTTP Error', error)
          },
          complete: () => {
            this.padsLoading = false;
            console.log('HTTP request Pads for Field completed.')
          }
        });
      this.subForPadsAndWells.push(sub);
    }
  }

  getWells(pad: IWellPayload) {
    if (pad.PadName) {
      this.wellsLoading = true;
      let sub = this.service.getWellsForPad(pad)
        .subscribe({
          next: (response: IWellForPad[]) => {
            this.wellsDropdownList = response;
          },
          error: (error) => {
            this.wellsLoading = false;
            console.error('HTTP Error', error)
          },
          complete: () => {
            this.wellsLoading = false;
            console.log('HTTP request Wells for Pad completed.');
          }
        });
      this.subscriptions.push(sub);
    }
  }

  getWellSuggestion(wellSearchPhrase: IFindWellPayload) {
    if (wellSearchPhrase.SearchPhrase && wellSearchPhrase.SearchPhrase.length > 2) {
      wellSearchPhrase.SearchPhrase = this.getWildcardSearchPhrase(wellSearchPhrase.SearchPhrase);

      let sub = this.service.GetWellSearchSuggestions(wellSearchPhrase)
        .subscribe({
          next: (response) => {
            this.wellListSuggestion = response;
          },
          error: (error) => {
              console.error('HTTP Error', error);
              this.wellsFindLoading = false;
          },
          complete: () => {
              console.log('HTTP request Well Suggestion completed.');
              this.wellsFindLoading = false;
          }
        });
      this.subscriptions.push(sub);
    }
  }

  private getWildcardSearchPhrase(phrase: string): string {
    let wildcardPhrase: string = phrase;
    wildcardPhrase = this.addEscapeCharacter(wildcardPhrase);
    if (!phrase.startsWith('^')) {
      wildcardPhrase = `%${wildcardPhrase}`;
    } else {
      wildcardPhrase = wildcardPhrase.replace('^', '');
    }

    if (!phrase.endsWith('$')) {
      wildcardPhrase = `${wildcardPhrase}%`
    } else {
      wildcardPhrase = wildcardPhrase.replace(/\$$/, '');
    }

    wildcardPhrase = wildcardPhrase.replace(/\*/g, '_').replace(/\?/g, '%');

    wildcardPhrase = wildcardPhrase.replace(/%%%%/g, '%');
    wildcardPhrase = wildcardPhrase.replace(/%%%/g, '%');
    wildcardPhrase = wildcardPhrase.replace(/%%/g, '%');

    return wildcardPhrase;
  }

  private addEscapeCharacter(wildcardPhrase: string): string {
    const escapeCharakter = '!';
    if (wildcardPhrase.indexOf('_') > -1) {
      let wildeArray = wildcardPhrase.split('_');
      wildcardPhrase = wildeArray.join(`${escapeCharakter}_`);
    }

    return wildcardPhrase;
  }

  getPadsPromise(field: IPadPayload): Promise<string[]> {
    this.padsLoading = true;
    return new Promise<string[]>((resolve, reject) => {
      this.service.getPadsForField(field)
        .subscribe((records: string[]) => {
          resolve(records);
          reject(new Error("getPadsForField promise rejected!"));
        });
    }).finally(() => {
      this.padsLoading = false;
    });
  }

  getWellsPromise(pad: IWellPayload): Promise<IWellForPad[]> {
    this.wellsLoading = true;
    return new Promise<IWellForPad[]>((resolve, reject) => {
      this.service.getWellsForPad(pad)
        .subscribe((records: IWellForPad[]) => {
          resolve(records);
          reject(new Error('getWellsForPad promise rejected!'));
        });
    }).finally(() => {
      this.wellsLoading = false;
    });
  }

  getAdHierarchyPath(wellMudiName: IWellSearchQuery): Promise<IAdHierarchyPath[]> {
    return new Promise<IAdHierarchyPath[]>((resolve, reject) => {
      this.service.GetAdHierarchyForWell(wellMudiName)
        .subscribe((records: IAdHierarchyPath[]) => {
          resolve(records);
          reject(new Error("getMudiRecords promise rejected!"));
        });
    });
  }

  selectSearchType(event: any) {
    this.wellFindPayload.SearchType = event.value;
    this.mudiWellFind.SearchType = event.value;
    this.searchTypeTxt = this.setSearchTypeTxt(event.value);
    this.searchPlaceholderTxt = this.setSearchPlaceholderTxt(this.searchTypeTxt.toString());

    this.devAreaDropdownControl.disabled = true;
    this.devAreaDropdownList = [];

    this.subDevAreaDropdownControl.disabled = true;
    this.subDevAreaDropdownList = [];

    this.padDropdownControl.disabled = true;
    this.padsDropdownList = [];

    this.wellDropdownControl.disabled = true;
    this.wellsDropdownList = [];

    this.clearAutocomplete();
    this.clearDropdowns('assetTeam', 'devAreaDropdownControl', 'subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');
    this.formValidator();
    this.cd.detectChanges();
  }

  setSearchTypeTxt(typeName: SearchType): string {
    switch (typeName) {
      case SearchTypes.PAD: {
        return SearchTypesLabels.PAD_NAME;
      }
      case SearchTypes.WELL: {
        return SearchTypesLabels.WELL_NAME;
      }
      case SearchTypes.API: {
        return SearchTypesLabels.API_NUMBER;
      }
      default: {
        throw new Error(`Search Type '${typeName}' does not exist.`);
      }
    }
  }

  setSearchPlaceholderTxt(searchType: string) {
    return `Please start typing ${searchType}...`;
  }

  assetTeamSelected!: IBasin | undefined;
  onBasinSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('devAreaDropdownControl', 'subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');
      this.assetTeamSelected = event.source.value;

      this.devAreaDropdownControl.disabled = true;
      this.subDevAreaDropdownControl.disabled = true;

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.saoRecordService.onBasinSelectByAssetTeam(this, event.source.value);

      this.devAreaDropdownControl.disabled = false;
      this.clearAutocomplete();
    }
  }

  devAreaSelected!: IField | undefined;
  onDevAreaSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.saoRecordService.onDevAreaSelect(this, event.source.value);

      this.clearAutocomplete();
    }
  }

  subDevAreasSelected!: IField | undefined;
  onSubDevAreaSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('padDropdownControl', 'wellDropdownControl');

      this.subDevAreasSelected = event.source.value;

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.fieldForPads.SubFieldName = this.subDevAreasSelected?.data.fieldName;
      this.getPads(this.fieldForPads);

      this.padDropdownControl.disabled = false;
      this.clearAutocomplete();
    }
  }

  padSelected!: string | undefined
  onPadSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('wellDropdownControl');
      this.padSelected = event.source.value;

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.padForWells.PadName = this.padSelected;
      this.getWells(this.padForWells);

      this.wellDropdownControl.disabled = false;
      this.clearAutocomplete();
    }
  }

  wellSelected!: IWellForPad | undefined
  onWellSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.wellSelected = event.source.value;
      this.checkIfAnySaoAlreadyAssigned(this.wellSelected?.propertyId);
      this.clearAutocomplete();
    }
  }

  clearAutocomplete() {
    this.addRecordForm.controls['recordByWell'].setValue('');
    this.wellListSuggestion = [];
  }

  clearDropdowns(...nameOfcontrol: string[]) {
    if (nameOfcontrol.indexOf('assetTeam') > -1) {
      this.assetTeamDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('subDevAreaDropdownControl') > -1) {
      this.subDevAreaDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('padDropdownControl') > -1) {
      this.padDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('wellDropdownControl') > -1) {
      this.wellDropdownControl.options.forEach(option => option.deselect());
    }
    if (nameOfcontrol.indexOf('devAreaDropdownControl') > -1) {
      this.devAreaDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('subDevAreaDropdownControl') > -1) {
      this.subDevAreaDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('padDropdownControl') > -1) {
      this.padDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('wellDropdownControl') > -1) {
      this.wellDropdownControl.options.forEach(option => option.deselect());
    }
  }

  onInitDiableDropdownSelection() {
    this.devAreaDropdownControl.disabled = true;
    this.subDevAreaDropdownControl.disabled = true;
    this.padDropdownControl.disabled = true;
    this.wellDropdownControl.disabled = true;
  }

  onKeyUpWellSearch(event: any) {
    let searchWell = event.target.value;
    this.subject.next(searchWell);
  }

  onSelectAutoCompleteField(event: any) {
    if (event.isUserInput) {
      let searchTypeName = event.source.value;
      this.wellListSuggestion = [searchTypeName];
      this.mudiWellFind.SearchTypeName = searchTypeName;
      this.processWellSearchQuery(this.mudiWellFind);
    }
  }

  openInfoDialog(id: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '210px';
    dialogConfig.width = '400px';
    dialogConfig.data = <IGenericDialogData>{
      dialogType: 'Information',
      content: 'The are already planned/acquired SA&O activities for this well. Would you like to edit?',
      buttons: 'Cancel,Edit'
    };

    const dialogRef = this.matDialog.open(GenericDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.router.navigate(['edit', { saoRecordId: id }]);
      } else {
        this.clearDropdowns('wellDropdownControl');
        this.clearAutocomplete();
      }
    });
  }

  async processWellSearchQuery(wellSearchQuery: IWellSearchQuery) {

    this.clearDropdowns('devAreaDropdownControl', 'subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');
    let hierarchyPaths = await this.getAdHierarchyPath(wellSearchQuery);

    if (wellSearchQuery.SearchType === SearchTypes.PAD) {
      //check for already assigned activities will occur on well selection
      this.fillDropdowns(hierarchyPaths, wellSearchQuery.SearchType);
      return;
    }

    let propId = hierarchyPaths[0].propertyId;
    let findResult: boolean = this.checkIfAnySaoAlreadyAssigned(propId);
    if (!findResult) {
      this.fillDropdowns(hierarchyPaths, wellSearchQuery.SearchType);
    }
  }

  private checkIfAnySaoAlreadyAssigned(
    propId: string | null | undefined): boolean {
    if (propId !== null && propId !== undefined) {
      let possibleMatch = this.allSaoRecords.find(record => record.data.externalPropertyId == propId);
      if (possibleMatch) {
        this.openInfoDialog(possibleMatch.id);
        return true;
      }
    }
    return false;
  }

  fillDropdowns(hierarchyPaths: IAdHierarchyPath[], searchedType: SearchType) {
    if (hierarchyPaths && hierarchyPaths.length >= 1) {

      this.devAreaDropdownControl.disabled = true;
      this.devAreaDropdownList = [];

      this.subDevAreaDropdownControl.disabled = true;
      this.subDevAreaDropdownList = [];

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.addRecordService.fillAsseTeam(hierarchyPaths, this);

      this.addRecordService.fillDevArea(hierarchyPaths, this);

      this.addRecordService.fillSubDevArea(hierarchyPaths, this);

      this.addRecordService.fillPadName(hierarchyPaths, this);

      this.addRecordService.fillWellName(hierarchyPaths, searchedType, this);

      this.formValidator('assetTeam', 'devArea', 'subDevelopmentArea', 'padName', 'wellName');
    }
  }

  cancel() {
    this.router.navigate(['records']);
  }

  async submitNewRecord() {

    if (!this.addRecordForm.valid) {
      this.formValidator('assetTeam', 'devArea', 'subDevelopmentArea', 'padName', 'wellName', 'assignedSaoOptions');
      return;
    }

    this.formValidator();

    this.submitLoading = true;

    this.acqOptsDataSource.data = this.saoOptionsComponentControl.acqOptsDataSource.data;
    this.plannedOptsDataSource.data = this.saoOptionsComponentControl.plannedOptsDataSource.data;

    let allFilesForUpload = this.acqOptsDataSource.data
      .map(saoOption => saoOption.files).flat(1);

    if (allFilesForUpload !== undefined && allFilesForUpload.length > 0) {
      await this.addRecordWithFiles(allFilesForUpload);
    } else {
      this.addRecord();
    }
  }

  async addRecordWithFiles(filesForUpload: IUploadedFileDto[]): Promise<void> {
    let functionResponse = await this.fileUploadService.uploadFileFromStageToOsdu(filesForUpload);
    let msg = 'Upload file(s) failed!';

    if (functionResponse && functionResponse?.runtimeStatus === 'Completed' &&
      functionResponse?.customStatus !== 'Failed' && functionResponse?.output) {
      this.getExistingSaoOptionsData(functionResponse.output);
      this.addRecord();
    } else {
      this.errorDialog(msg);
      this.submitLoading = false;
    }

    this.cd.detectChanges();
  }

  addRecord(): void {
    let saoOptions: IUpsertSaoOptionsPayload[] = [];
    this.acqOptsDataSource.data.forEach(saoOptionRecord => {

      saoOptions.push(<IUpsertSaoOptionsPayload>{
        Comment: saoOptionRecord.comment,
        OptionType: saoOptionRecord.optionType,
        OptionOsduId: saoOptionRecord.optionId,
        DataAcquisitionTime: saoOptionRecord.dataAcquisitionTime,
        AddedBy: saoOptionRecord.addedBy,
        Files: saoOptionRecord.files
      });
    });

    this.plannedOptsDataSource.data.forEach(saoOptionRecord => {
      saoOptions.push(<IUpsertSaoOptionsPayload>{
        Comment: saoOptionRecord.comment,
        OptionType: saoOptionRecord.optionType,
        OptionOsduId: saoOptionRecord.optionId,
        DataAcquisitionTime: saoOptionRecord.dataAcquisitionTime,
        AddedBy: saoOptionRecord.addedBy,
        Files: []
      });
    });

    let SaoRecordDataDto =
      <IUpsertRecordPayload>{
        ExternalPropertyId: this.wellSelected?.propertyId,
        SaoOptions: saoOptions,
        OsduId: null,
        OsduWellId: null,
        Kind: environment.osduSurveillanceRecordKind
      }

    this.updateService.postUpsertSaoRecord(SaoRecordDataDto)
      .subscribe({
        next: (res) => {
          console.log('HTTP response', res);
          this.submitLoading = true;
        },
        error: (err) => {
          console.error('HTTP Error', err)
          this.submitLoading = false;
          let msg = 'Add record failed!';
          (async () => {
            await this.dialog.openGenericDialog('Error', msg, 'Ok');
          })();
          this.cd.detectChanges();
        },
        complete: () => {
          console.log('HTTP request completed.');
          this.submitLoading = false;
          this.cd.detectChanges();
          this.store.dispatch(new RequestRecords());
          this.router.navigate(['records']);
        }
      });
  }

  getExistingSaoOptionsData(osduResponses: IFileUploadOsduResponse[]) {
    osduResponses.forEach(osduResponse => {
      let fileUploadedPredicate = (fileUploaded: IUploadedFileDto) =>
        fileUploaded.fileVersions.some(fileVersion => fileVersion.blobName === osduResponse.blobName);
      let saoOptionByBlobNameAndFileNamePredicate = (saoOption: any) =>
        saoOption.files.some(fileUploadedPredicate);

      let saoOption = this.acqOptsDataSource.data.find(x => saoOptionByBlobNameAndFileNamePredicate(x));
      let saoOptionIndex = this.acqOptsDataSource.data.findIndex(x => saoOptionByBlobNameAndFileNamePredicate(x));

      if (saoOption?.files.some(x => x.fileVersions.some(fileVersion =>
        fileVersion.blobName === osduResponse.blobName && osduResponse.isUploadedToOsdu === true))) {
        let fileUploadedIndex: number = saoOption?.files.findIndex((fileUploaded: IUploadedFileDto) =>
          fileUploaded.fileVersions.some(fileVersion => fileVersion.blobName == osduResponse.blobName));

        if (fileUploadedIndex < 0) {
          return;
        }

        let versionIndex = saoOption?.files[fileUploadedIndex]?.fileVersions.findIndex(x => x.blobName === osduResponse.blobName);

        if (versionIndex < 0) {
          return;
        }

        let fileGuid = this.acqOptsDataSource.data[saoOptionIndex].files[fileUploadedIndex].fileVersions[versionIndex].tempFileGuid;
        this.acqOptsDataSource.data[saoOptionIndex].files[fileUploadedIndex].fileVersions[versionIndex] = <IFileVersion>{
          file: osduResponse.osduId,
          versionComment: osduResponse.fileComment,
          blobName: osduResponse.blobName,
          fileName: osduResponse.fileName,
          tempFileGuid: fileGuid
        };
      }
    });
  }

  formValidator(...keyName: string[]): void {
    for (const key of Object.keys(this.visibleFormWarning)) {
      let status = this.addRecordForm.controls[key].status
      if (status === 'INVALID' && keyName.includes(key)) {
        this.visibleFormWarning[key as keyof typeof this.visibleFormWarning] = true;
      } else {
        this.visibleFormWarning[key as keyof typeof this.visibleFormWarning] = false;
      }
    };
  }

  private errorDialog(msg: string) {
    (async () => {
      await this.dialog.openGenericDialog('Error', msg, 'Ok');
    })();
  }

  plannedOptColumns = [
    {
      columnDef: "saoOptioName",
      header: "Planned SA&O",
      cell: (option: ISaoOptionData) => `${option.saoOptionName}`
    }
  ];

  displayedPlannedOptCols = this.plannedOptColumns.map(c => c.columnDef);

  acqOptionsColumns = [
    {
      columnDef: "saoOptioName",
      header: "Acquired SA&O",
      cell: (option: ISaoOptionData) => `${option.saoOptionName}`
    }
  ];
  displayedAcqOptCols = this.acqOptionsColumns.map(c => c.columnDef);
}
