import { Action } from "@ngrx/store"
import { ISaoOptionsCacheState } from "../reducers/fetch-sao-options.reducer";

export enum FetchSaoOptionsActions {
    SAO_OPTIONS_REQUEST = '[SAO_OPTIONS_CACHE] Request SAO Options',
    SAO_OPTIONS_REQUEST_SUCCESS = '[SAO_OPTIONS_CACHE] SAO Options request - success',
    SAO_OPTIONS_REQUEST_FAILED = '[SAO_OPTIONS_CACHE] SAO Options request - failed' 
}

export class RequestSaoOptions implements Action {
    readonly type: string = FetchSaoOptionsActions.SAO_OPTIONS_REQUEST;
    constructor(public payload: ISaoOptionsCacheState = <ISaoOptionsCacheState>{}) {}
}

export class SaoOptionsRequestSuccess implements Action {
    readonly type: string = FetchSaoOptionsActions.SAO_OPTIONS_REQUEST_SUCCESS;
    constructor(public payload: ISaoOptionsCacheState) {}
}

export class SaoOptionsRequestFailed implements Action {
    readonly type: string = FetchSaoOptionsActions.SAO_OPTIONS_REQUEST_FAILED;
    constructor(public payload: ISaoOptionsCacheState) {}
}

export type FetchSaoOptionsAction 
= RequestSaoOptions | SaoOptionsRequestSuccess | SaoOptionsRequestFailed;