import { IWellDetails } from "src/app/models/well-details.model";
import { FetchWellDetailsAction, FetchWellsDetailsActions } from "../actions/fetch-wells-details.action";

export interface IWellsDetailsCacheState {
    wellsDetailsList: IWellDetails[],
    wellsIds: string[],
    wellsDetailsLoading: boolean,
    wellDetailsRequestError: any
}

const initialState: IWellsDetailsCacheState = {
    wellsDetailsList: [],
    wellsIds: [],
    wellsDetailsLoading: false,
    wellDetailsRequestError: null
}

export function wellsDetailsReducer(state: IWellsDetailsCacheState = initialState, action: FetchWellDetailsAction): IWellsDetailsCacheState {

    switch(action.type) {
        case FetchWellsDetailsActions.WELLS_DETAILS_REQUEST: {
            state = <IWellsDetailsCacheState>{
                wellsDetailsList: [],
                wellsIds: action.payload.wellsIds,
                wellsDetailsLoading: true, 
                wellDetailsRequestError: null
            };
            return state;
        }
        case FetchWellsDetailsActions.WELLS_DETAILS_REQUEST_SUCCESS: {
            state = <IWellsDetailsCacheState> {
                wellsDetailsList: action.payload.wellsDetailsList, 
                wellsIds: action.payload.wellsIds,
                wellsDetailsLoading: false,
                wellDetailsRequestError: null
            };
            return state;
        }
        case FetchWellsDetailsActions.WELLS_DETAILS_REQUEST_FAILED: {
            state = <IWellsDetailsCacheState> {
                wellsDetailsList: [],
                wellsIds: [],
                wellsDetailsLoading: action.payload.wellsDetailsLoading,
                wellDetailsRequestError: action.payload.wellDetailsRequestError
            };
            return state;
        }
        default: {
            return state;
        }
    }
}