import { IBasin } from "../models/osdu/basin.model";
import { IField } from "../models/osdu/field.model";
import { ISaoOption } from "../models/osdu/sao-option.model";
import { ITargetFormation } from "../models/osdu/target-formation.model";
import { ISaoFlattenedRecordDataSource } from "../models/sao-record-flat-table.model";
import { ISaoRecordTableDataSource } from "../models/sao-record-search-table.model";
import { flattenSaoRecords, formatTgtFormation } from "./helpers.util";

export class FilterParams {
  constructor(public selectedBasin: IBasin | undefined,
    public allFieldsOsduRecords: IField[],
    public selectedField: IField | undefined,
    public selectedSubFields: IField[],
    public selectedTargetFormations: ITargetFormation[],
    public selectedSaoOptions: ISaoOption[],
    public filterByFiles: boolean,
    public filterByActivityType: string) {}
}

export class FilteringResult {
  constructor(
    public filteredRecords: ISaoRecordTableDataSource[],
    public filteredFlattenedRecords: ISaoFlattenedRecordDataSource[]) {}
}

export class RecordsFilter {
  private saoRecordsdata: ISaoRecordTableDataSource[];
  private saoFlatttenedRecordsData: ISaoFlattenedRecordDataSource[];
  private options: ISaoOption[];

  constructor(saoRecords: ISaoRecordTableDataSource[],
              options: ISaoOption[]) {
    this.saoRecordsdata = saoRecords;
    this.options = options;
    this.saoFlatttenedRecordsData = [];
  }

  private getFilteredRecords(): FilteringResult {
    return new FilteringResult(this.saoRecordsdata, this.saoFlatttenedRecordsData);
  }

  filter(filterParams: FilterParams
  ): FilteringResult {

    return this.filterByBasin(filterParams.selectedBasin, filterParams.allFieldsOsduRecords)
      .filterByDevArea(filterParams.selectedField)
      .filterBySubDevAreas(filterParams.selectedSubFields)
      .filterByTargetFormations(filterParams.selectedTargetFormations)
      .flattenToFilterActivities()
      .filterBySaoOptions(filterParams.selectedSaoOptions)
      .filterByAttachedFiles(filterParams.filterByFiles)
      .filterByActivityTypes(filterParams.filterByActivityType)
      .getFilteredRecords();
  }

  private filterByBasin(selectedBasin: IBasin | undefined, allFieldsOsduRecords: IField[]): this {
    if (selectedBasin === undefined) {
      return this;
    }
    else {
      let fields = allFieldsOsduRecords.filter(field => field.data.geoContexts[0]?.basinId == selectedBasin.id);
      let filteredData: ISaoRecordTableDataSource[] = [];

      this.saoRecordsdata.forEach(record => {
        if (fields.findIndex(devArea => devArea.data?.fieldName == record.data.developmentArea) > -1) {
          filteredData.push(record);
        }
      })

      this.saoRecordsdata = filteredData;
    }
    return this;
  }

  private filterByDevArea(selectedField: IField | undefined): this {
    if (selectedField === undefined) {
      return this;
    }
    else {
      let filteredFieldOsduRecords = this.saoRecordsdata.filter(record => record.data.developmentArea == selectedField.data?.fieldName);
      this.saoRecordsdata = filteredFieldOsduRecords;
    }
    return this;
  }

  private filterBySubDevAreas(selectedSubFields: IField[]): this {
    if (selectedSubFields === undefined) {
      return this;
    }
    if (selectedSubFields.length > 0) {
      let selectedSubFieldsNames = selectedSubFields.map(field => field.data?.fieldName);
      let filteredSubFieldOsduRecords = this.saoRecordsdata.filter((record) => selectedSubFieldsNames.indexOf(record.data.subDevelopmentArea) > -1);
      this.saoRecordsdata = filteredSubFieldOsduRecords;
    }

    return this;
  }

  private filterByTargetFormations(selectedTargetFormations: ITargetFormation[]): this {
    if (selectedTargetFormations === undefined) {
      return this;
    }
    if (selectedTargetFormations.length > 0) {
      let selectedTargetFormationsNames = selectedTargetFormations.map(
        formation => formatTgtFormation(formation.data.Name));
      this.saoRecordsdata = this.saoRecordsdata.filter(record => selectedTargetFormationsNames.indexOf(record.data.targetFormation) > -1);
    }

    return this;
  }

  private filterBySaoOptions(selectedSaoOptions: ISaoOption[]): this {
    if (selectedSaoOptions === undefined) {
      return this;
    }
    if (selectedSaoOptions.length > 0) {
      let selectedOptionsIds = selectedSaoOptions.map(option => option.id);
      this.saoRecordsdata = this.saoRecordsdata.filter(record => record.data.assignedSaoOptions?.some(option => selectedOptionsIds.indexOf(option.optionId) > -1));
      this.saoFlatttenedRecordsData = this.saoFlatttenedRecordsData.filter(record => selectedSaoOptions.find(option => option.data.saoOptionName === record.data.activityName));
    }

    return this;
  }

  private filterByAttachedFiles(filterByFiles: boolean): this {
    if (filterByFiles) {
      this.saoRecordsdata = this.saoRecordsdata.filter(
        record => record.data.assignedSaoOptions.every(option => option.files.length === 0));

      this.saoFlatttenedRecordsData = this.saoFlatttenedRecordsData.filter(
        record => record.data.assignedSaoOptions.every(option => option.files.length === 0));
    }

    return this;
  }

  private flattenToFilterActivities(): this {
    this.saoFlatttenedRecordsData = flattenSaoRecords(this.saoRecordsdata, this.options);

    return this;
  }

  private filterByActivityTypes(activityType: string): this {
    if (activityType === undefined || activityType === '') {
      return this;
    }

    this.saoFlatttenedRecordsData 
      = this.saoFlatttenedRecordsData.filter(record => record.data.activityType.toString() === activityType);

    this.saoRecordsdata
      = this.saoRecordsdata.filter(record => record.data.assignedSaoOptions
                           .some(opt => opt.optionType.toString() === activityType));

    return this;
  }
}
