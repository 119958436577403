import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { IData, IFileInfoResponse, IFileVersionUpdate } from 'src/app/models/files/file-info-upload';
import { IFileUploadBlob } from 'src/app/models/files/file-upload-blob-response.model';
import { IAssignedSaoOptionDto, IUploadedFileDto, IUploadedFileTempDto, UploadType } from 'src/app/models/sao-option-dto.model';
import { IFileVersion } from 'src/app/models/osdu/sao-record.model';
import { getSaoIngestionEndpointUrl, SaoIngestionEndpoints } from 'src/app/util/api-definitions.util';
import Utils from 'src/app/util/utils';

@Component({
  selector: 'sao-options-file',
  templateUrl: './sao-options-file.component.html',
  styleUrls: ['./sao-options-file.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class SaoOptionsFileComponent implements OnInit {

  @ViewChild(MatSort)
  private readonly sort!: MatSort;
  @Input()
  assignedSaoOption!: IAssignedSaoOptionDto;
  @Input()
  saoRecord: any;
  @Output()
  updateAssignedSaoOptions: EventEmitter<IUploadedFileTempDto> = new EventEmitter<IUploadedFileTempDto>();


  previewViewerAllowExt: string[] = ['PPT', 'PPTX', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF'];
  fileInfoResponseVersions: IFileInfoResponse[] = [];
  fileInfoDataSource: any;
  filesDataSource: MatTableDataSource<IUploadedFileDto> = new MatTableDataSource<IUploadedFileDto>();
  sortingColumn: string = "";
  sortingDirection: string = "";
  optionGuid: string = '';
  showPlusIcon: boolean = false;

  constructor(private matDialog: MatDialog,
    private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit() {
    this.setPlusIconVisibility();

    this.optionGuid = this.assignedSaoOption.tempOptionGuid ?? crypto.randomUUID();
    this.filesDataSource = new MatTableDataSource<IUploadedFileDto>(this.assignedSaoOption.files.map(file => {
      let versionGuid = file.tempVersionGuid ?? crypto.randomUUID();
      let fileVersion = file.fileVersions.map(fileVer => {
        let fileGuid = fileVer.tempFileGuid ?? crypto.randomUUID();
        return {
          ...fileVer,
          tempFileGuid: fileGuid
        } as unknown as IFileVersion
      });
      return {
        ...file,
        fileVersions: fileVersion,
        tempOptionGuid: this.optionGuid,
        tempVersionGuid: versionGuid
      } as unknown as IUploadedFileDto
    }));
  }

  setPlusIconVisibility() {
    if (this.router.url.includes('/add') || this.router.url.includes('/edit')) {
      this.showPlusIcon = true;
    } else {
      this.showPlusIcon = false;
    }
  }

  ngAfterViewInit() {
    this.filesDataSource.data.forEach((file: IUploadedFileDto) => {
      this.getFileVersionsInfo(file);
    });

    this.addSorting();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.fileInfoResponseVersions = [];
    this.changeDetectorRef.detectChanges();
  }

  private updateSaoOption(uploadedFileTempDtos: IUploadedFileTempDto) {
    this.updateAssignedSaoOptions.emit(uploadedFileTempDtos);
  }

  addNewFileToModel(fileUploadResult: IFileUploadBlob[]) {
    if (fileUploadResult && fileUploadResult.length > 0) {
      fileUploadResult.forEach((fileUpload: IFileUploadBlob) => {
        this.addResultToFileInfoResponse(fileUpload);

        this.filesDataSource.data = this.filesDataSource.data.concat({
          fileName: fileUpload.fileName,
          fileVersions: [<IFileVersion>{
            versionComment: fileUpload.fileComment,
            blobName: fileUpload.blobName,
            tempFileGuid: fileUpload.tempFileGuid,
            fileName: Utils.generateUniqueFileName(fileUpload.fileName),
            file: ''
          }],
          tempOptionGuid: fileUpload.tempOptionGuid,
          tempVersionGuid: fileUpload.tempVersionGuid
        } as unknown as IUploadedFileDto);
      });

      this.filesDataSource = new MatTableDataSource<IUploadedFileDto>(this.filesDataSource.data);
      let dtoObj = <IUploadedFileTempDto>{
        uploadedFileDtos: this.filesDataSource.data,
        tempOptionGuid: null
      };
      this.updateSaoOption(dtoObj);
    }
  }

  addNewFileVersionToModel(fileUploadResult: IFileUploadBlob[]) {
    if (fileUploadResult && fileUploadResult.length > 0) {
      fileUploadResult.forEach((fileUpload: IFileUploadBlob) => {
        this.addResultToFileInfoResponse(fileUpload);

        let index = this.filesDataSource.data.findIndex(x => x.tempVersionGuid === fileUpload.tempVersionGuid
          && x.tempOptionGuid === fileUpload.tempOptionGuid);

        if (index > -1) {
          let fileVersion = <IFileVersion>{
            blobName: fileUpload.blobName,
            versionComment: fileUpload.fileComment,
            fileName: Utils.generateUniqueFileName(fileUpload.fileName),
            tempFileGuid: fileUpload.tempFileGuid
          };
          let arrayToExtend = this.filesDataSource.data[index].fileVersions;
          this.filesDataSource.data[index].fileVersions = [...arrayToExtend, fileVersion];
        }
      });

      this.filesDataSource = new MatTableDataSource<IUploadedFileDto>(this.filesDataSource.data);
      let dtoObj = <IUploadedFileTempDto>{
        uploadedFileDtos: this.filesDataSource.data,
        tempOptionGuid: null
      };
      this.updateSaoOption(dtoObj);
      this.changeDetectorRef.detectChanges();
    }
  }

  private addResultToFileInfoResponse(fileUpload: IFileUploadBlob): void {
    this.fileInfoResponseVersions = this.fileInfoResponseVersions.concat({
      data: {
        name: fileUpload.fileName,
        description: fileUpload.fileComment,
      } as unknown as IData,
      versionComment: fileUpload.fileComment,
      createTime: '',
      createUser: '',
      tempVersionGuid: fileUpload.tempVersionGuid,
      tempOptionGuid: fileUpload.tempOptionGuid,
      tempFileGuid: fileUpload.tempFileGuid
    } as IFileInfoResponse);
  }

  deleteVersionEventEmit(fileVersionUpdate: IFileVersionUpdate) {
    let versionIndex = this.filesDataSource.data.findIndex(x => x.tempVersionGuid === fileVersionUpdate.tempVersionGuid
      && x.tempOptionGuid === fileVersionUpdate.tempOptionGiud);

    let tempOptionGuid: string | null = null;
    let fileIndex = -1;
    if (versionIndex > -1) {
      fileIndex = this.filesDataSource.data[versionIndex].fileVersions.findIndex(
        x => x.tempFileGuid === fileVersionUpdate.tempFileGuid);
    }

    if (fileIndex > -1) {
      if (fileVersionUpdate.eventType === 'UpdateFile') {

        this.filesDataSource.data[versionIndex].fileVersions.splice(fileIndex, 1);
        this.filesDataSource = new MatTableDataSource<IUploadedFileDto>(this.filesDataSource.data);
      }

      if (fileVersionUpdate.eventType === 'UpdateVersion') {
        tempOptionGuid = fileVersionUpdate.tempOptionGiud;
        this.filesDataSource.data.splice(versionIndex, 1);
        this.filesDataSource = new MatTableDataSource<IUploadedFileDto>(this.filesDataSource.data);
      }

      let dtoObj = <IUploadedFileTempDto>{
        uploadedFileDtos: this.filesDataSource.data,
        tempOptionGuid: tempOptionGuid
      };
      this.updateSaoOption(dtoObj);
      this.fileInfoResponseVersions = this.fileInfoResponseVersions.filter(x => x.tempFileGuid !== fileVersionUpdate.tempFileGuid);
    }

    this.changeDetectorRef.detectChanges();
  }

  getFileVersionsInfo(file: IUploadedFileDto) {
    let versionGuid = file.tempVersionGuid;
    let optionGuid = file.tempOptionGuid;
    file.fileVersions.forEach(fileVersion => {
      this.getFileVersions(fileVersion.file, false).subscribe({
        next: (event: any) => {
          if (event?.body) {
            this.fileInfoResponseVersions = this.fileInfoResponseVersions.concat({
              ...event.body[0],
              versionComment: fileVersion.versionComment,
              osduFileId: fileVersion.file,
              tempVersionGuid: versionGuid,
              tempOptionGuid: optionGuid,
              tempFileGuid: fileVersion.tempFileGuid
            } as IFileInfoResponse);
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        }
      });
    });
  }

  uploadNewFile(evt: Event) {
    let dialogRef = this.matDialog.open(FileUploadComponent, {
      width: '60%',
      panelClass: 'upload-file',
      data: {
        uploadFileDto: this.filesDataSource.data.length === 0 ? [<IUploadedFileDto>{ tempOptionGuid: this.optionGuid }] : this.filesDataSource.data,
        uploadType: 'NewFile' as UploadType
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((fileUploadResult: IFileUploadBlob[]) => {
      this.addNewFileToModel(fileUploadResult);
      this.changeDetectorRef.detectChanges();
    });
  }

  uploadNewFileVersion(evt: Event, uploadFileDto: IUploadedFileDto) {
    let dialogRef = this.matDialog.open(FileUploadComponent, {
      width: '60%',
      panelClass: 'upload-file',
      data: {
        uploadFileDto: [uploadFileDto],
        uploadType: 'NewVersion' as UploadType
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((fileUploadResult: IFileUploadBlob[]) => {
      this.addNewFileVersionToModel(fileUploadResult);
      this.changeDetectorRef.detectChanges();
    });
  }

  addSorting() {
    this.filesDataSource.sort = this.sort;
    this.filesDataSource.sortingDataAccessor =
      (row: any, columnName: string): string => row.data[columnName];
  }

  getFileVersionByFileId(uploadFileDto: IUploadedFileDto): IFileInfoResponse[] | undefined {
    if (uploadFileDto === null || uploadFileDto === undefined || !this.fileInfoResponseVersions.some(x => x)) {
      return undefined;
    }

    return this.fileInfoResponseVersions.filter(x => x.tempVersionGuid === uploadFileDto.tempVersionGuid
      && x.tempOptionGuid === uploadFileDto.tempOptionGuid);
  }

  getFileVersions(file: any, reportProgress: boolean): Observable<HttpEvent<any>> {
    let payload = {
      fileVersionId: file,
    }

    let url = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.GET_SAO_FILE_VERSIONS_BY_FILE_ID);
    let request = new HttpRequest('POST', url, payload, {
      reportProgress: reportProgress
    });

    return this.httpClient.request(request);
  }

  fileOptColumns = [
    {
      columnDef: "fileName",
      header: "Attachments",
      cell: (option: IUploadedFileDto) => `${option.fileName}`
    }
  ]

  displayedFilesOptCols = this.fileOptColumns.map(c => c.columnDef);

}



