import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDashboardFilterSaoRecordDataSource } from '../filter/filter.component';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { IDashboardTableSaoRecordSource } from './table/table.component';
import { FilteringResult } from 'src/app/util/sao-records-filter.util';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  recordsState!: Observable<IDashboardFilterSaoRecordDataSource>;
  recordSource!: Observable<IDashboardTableSaoRecordSource>;
  mapClickedWell!: Observable<ISaoRecordTableDataSource>;
  reloadMap!: Observable<boolean>;

  private recordsDataSourceSubject: BehaviorSubject<IDashboardFilterSaoRecordDataSource>
    = new BehaviorSubject<IDashboardFilterSaoRecordDataSource>(<IDashboardFilterSaoRecordDataSource>{
      filteringResult: <FilteringResult>{},
      loading: true
    });

  private recordDataSubject: BehaviorSubject<IDashboardTableSaoRecordSource>
    = new BehaviorSubject<IDashboardTableSaoRecordSource>(<IDashboardTableSaoRecordSource>{
      saoRecordTableDataSource: <ISaoRecordTableDataSource><unknown>{ data: null },
      clickSource: 'MAP'
    });

  private mapClickedWellSubject: BehaviorSubject<ISaoRecordTableDataSource>
    = new BehaviorSubject<ISaoRecordTableDataSource>(<ISaoRecordTableDataSource><unknown>{ data: null });

  private reloadMapSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.recordsState = this.getSaoRecordDataSource();
    this.recordSource = this.getRecordData();
    this.mapClickedWell = this.getMapClickedWellData();
    this.reloadMap = this.reloadMapData();
  }

  onFilterData(recordsDataSource: IDashboardFilterSaoRecordDataSource) {
    this.recordsDataSourceSubject.next(recordsDataSource);
  }

  private getSaoRecordDataSource(): Observable<IDashboardFilterSaoRecordDataSource> {
    return this.recordsDataSourceSubject.asObservable();
  }

  onTableRowClick(recordData: IDashboardTableSaoRecordSource) {
    this.recordDataSubject.next(recordData);
  }

  private getRecordData(): Observable<IDashboardTableSaoRecordSource> {
    return this.recordDataSubject.asObservable();
  }

  onMapWellClick(wellData: ISaoRecordTableDataSource) {
    this.mapClickedWellSubject.next(wellData);
  }

  private getMapClickedWellData(): Observable<ISaoRecordTableDataSource> {
    return this.mapClickedWellSubject.asObservable();
  }

  onReloadMap(reload: boolean) {
    this.reloadMapSubject.next(reload);
  }

  private reloadMapData(): Observable<boolean> {
    return this.reloadMapSubject.asObservable();
  }
}
