import { environment } from 'src/environments/environment';

export enum DataManagerSearchServiceEndpoints {
  ALL_SAO_RECORDS = "/search/sao-records-list",
  ALL_SAO_OPTIONS = "/search/sao-options-list",
  ALL_BASINS = "/search/ad-hierarchy-basins-list",
  ALL_FIELDS = "/search/ad-hierarchy-fields-list",
  ALL_FORMATIONS = "/search/geological-formation-list",
  ALL_CURRENCIES = "/search/currency-list",
  ALL_WELLS = "/search/wells",
  ALL_TARGET_FORMATION = "/search/reservoirs-list",
  DATASETS_BY_IDS = "/search/datasets",
  DATASETS_DETAILS_BY_IDS = "/search/datasets-details",
  DOWNLOAD_FILE = "/search/download-file"
}

export enum SaoIngestionEndpoints {
  DELETE_FILE_VERSIONS = "/api/DeleteFileVersions",
  GET_SAO_FILE_VERSIONS_BY_FILE_ID = "/api/GetSaoFileVersionsByFileId",
  INGEST_SURVEILLANCE_RECORD = "/api/IngestSurveillanceRecord",
  UPDATE_SAO_RECORD = "/api/UpdateDMSaoRecord",
  UPLOAD_LARGE_FILE = "/api/SaoAppendBlockToStorage",
  UPLOAD_SAO_FILE = "/api/SaoFilesUploadToBlobStorage",
  UPLOAD_SAO_FILE_IN_CHUNKS_TO_OSDU = "/api/SaoFilesUploadToOsduInChunks",
  UPLOAD_SAO_FILE_TO_OSDU = "/api/SaoFilesUploadToOsdu"
}

export enum SaoFrontendApiEndpoints {
  WELL_DETAILS = "/api/GetWellsDetailsListByWellExtIds",
  WELLS_FOR_PAD = "/api/GetWellsListForPad",
  WELLS_SEARCH_SUGGESTIONS = "/api/GetWellSearchSuggestions",
  PADS_FOR_FIELD = "/api/GetPadsListForField",
  AD_HIERARCHY_BY_WELLNAME = "/api/GetWellAdHierarchyByWellName",
  GET_SAO_AZURE_MAPS_SETTINGS = "/api/GetAzureMapKeyVaultSecrets",
  AD_HIERARCHY_ASSET_TEAMS = "/api/GetAdHierarchyAssetTeams",
  AD_HIERARCHY_DEV_AREAS = "/api/GetAdHierarchyDevAreas",
  AD_HIERARCHY_SUB_DEV_AREAS = "/api/GetAdHierarchySubDevAreas",
  AD_HIERARCHY_PADS = "/api/GetAdHierarchyPads",
  AD_HIERARCHY_WELLS = "/api/GetAdHierarchyWells"
}

export type ApiDefinitions = SaoFrontendApiEndpoints | SaoIngestionEndpoints | DataManagerSearchServiceEndpoints

export const getDataManagerEndpointUrl = (endpoint: DataManagerSearchServiceEndpoints): string => {
  let baseUrl = environment.dmSearchServiceBaseUrl;
  return getAbsoluteUrl(baseUrl, endpoint);
}

export const getSaoIngestionEndpointUrl = (endpoint: SaoIngestionEndpoints): string => {
  let baseUrl = environment.saoIngestionServiceBaseUrl;
  return getAbsoluteUrl(baseUrl, endpoint);
}

export const getSaoFrontendApiEndpointUrl = (endpoint: SaoFrontendApiEndpoints): string => {
  let baseUrl = environment.saoFrontendApiBaseUrl;
  return getAbsoluteUrl(baseUrl, endpoint);
}

const getAbsoluteUrl = (baseUrl: string, apiDef: ApiDefinitions): string => {
  let relativeUrl: string = apiDef;
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  if (relativeUrl.startsWith('/')) {
    relativeUrl = relativeUrl.slice(1);
  }

  return [baseUrl, relativeUrl].join('/');
}
