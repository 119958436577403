import { EventEmitter, Injectable, OnDestroy, Output } from "@angular/core";
import { azureMapsControl } from "./azure-maps-wrapper";
import { Observable, Observer, Subscription } from "rxjs";
import { IDashboardTableSaoRecordSource } from "../table/table.component";
import { IAzureMapsSettingsCacheState } from "src/app/store/reducers/fetch-azure-maps-settings.reducer";
import { Store, select } from "@ngrx/store";
import { IAppState } from "src/app/store/reducers";
import { HttpService } from "src/app/services/http.service";
import { IGeoJSON, IGeometry, IProperties, IStyle } from "src/app/models/maps/geo-json.model";
import { IPolygonColorSchema } from "src/app/models/maps/polygon-color-schema.model";
import { ISaoRecordTableDataSource } from "src/app/models/sao-record-search-table.model";
import { OptionType } from "src/app/models/osdu/sao-record.model";
import { popupTemplate } from "./azure-maps-popup-template";

@Injectable({
  providedIn: 'root'
})
export class AzureMapsService implements OnDestroy {
  @Output()
  clickedWellId: EventEmitter<string> = new EventEmitter<string>();

  private atlas: any;
  private map!: any;
  private mapPopup: any;
  private saoOpenButton: HTMLElement | null = null;
  private closePopupButton: HTMLElement | null = null;
  private popupTemplate: string = popupTemplate;

  @Output()
  openSaoOptionDialog: EventEmitter<string> = new EventEmitter<string>();
  private azureMapsSettingsCacheState$: Observable<IAzureMapsSettingsCacheState>;
  private subscriptions: Subscription[] = [];

  constructor(store: Store<IAppState>,
    private httpService: HttpService) {
    this.atlas = azureMapsControl();
    this.azureMapsSettingsCacheState$ = store.pipe(select('azureMapsSettingsCacheState'));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getMap(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      let mapIsLoading: boolean = true;
      let sub = this.azureMapsSettingsCacheState$.subscribe(state => {
        mapIsLoading = state.azureMapsSettingsLoading;
        if (!state.azureMapsSettingsLoading) {
          this.map = new this.atlas.Map('mapContainer', {
            center: [0, 0],
            zoom: 12,
            language: 'en-US',
            showLogo: true,
            showFeedbackLink: false,
            dragRotateInteraction: false,
            style: 'road_shaded_relief',
            scrollZoomInteraction: false,
            authOptions: {
              authType: this.atlas.AuthenticationType.subscriptionKey,
              subscriptionKey: state.azureMapsSettings.azureMapsKey
            }
          });
        }

        if (!mapIsLoading) {
          this.addMapControls();

          this.map.events.add('load', () => {
            observer.next(this.map);
            observer.complete();
          });
        }
      });
      this.subscriptions.push(sub);
    });
  }

  private addMapControls(): void {
    this.map.controls.add(new this.atlas.control.StyleControl({
      mapStyles: ['road', 'grayscale_dark', 'night', 'road_shaded_relief', 'satellite', 'satellite_road_labels'],
      layout: 'list'
    }), {
      position: 'top-right'
    });

    this.map.controls.add(new this.atlas.control.ZoomControl(), {
      position: 'bottom-right'
    });

    this.map.controls.add(new this.atlas.control.PitchControl(), {
      position: 'top-right'
    });

    this.map.controls.add(new this.atlas.control.CompassControl(), {
      position: 'top-right'
    });
  }

  initializeDataSource(): any {
    return new this.atlas.source.DataSource();
  }

  addBubbleLayer(dataSource: any, layersExclusion: string[]): void {
    let bubbleLayer = new this.atlas.layer.BubbleLayer(dataSource, null, {
      radius: [
        'case',
        ['has', 'radius', ['get', 'style']],
        ['get', 'radius', ['get', 'style']],
        3
      ],
      strokeColor: [
        'case',
        ['has', 'strokeColor', ['get', 'style']],
        ['get', 'strokeColor', ['get', 'style']],
        '#000000'
      ],
      strokeWidth: [
        'case',
        ['has', 'strokeWidth', ['get', 'style']],
        ['get', 'strokeWidth', ['get', 'style']],
        2
      ],
      color: [
        'case',
        ['has', 'color', ['get', 'style']],
        ['get', 'color', ['get', 'style']],
        '#ffff73'
      ]
    });

    this.clearUserLayers(this.map.layers, layersExclusion);
    this.map.layers.add(bubbleLayer);

    let popup = new this.atlas.Popup({
      pixelOffset: [0, -1],
      closeButton: false,
      fillColor: 'rgba(11, 45, 113, 1)'
    });

    this.mapPopup = popup;

    this.map.events.add('click', bubbleLayer, (e: any) => {
      if (e.shapes && e.shapes.length > 0) {

        let properties = e.shapes[0].getProperties();

        let content = this.popupTemplate
          .replace(/{assetTeam}/g, properties.assetTeam)
          .replace(/{devArea}/g, properties.devArea)
          .replace(/{subDevArea}/g, properties.subDevArea)
          .replace(/{padName}/g, properties.padName)
          .replace(/{wellName}/g, properties.wellName)
          .replace(/{wellID}/g, properties.wellId);

        let coordinate = e.shapes[0].getCoordinates();

        popup.setOptions({
          content: content,
          position: coordinate
        });

        popup.open(this.map);
        this.addPopupEvents();
        this.emitWellIdOnClick(properties.wellId);
      }
    });

    this.map.events.add('layerremoved', bubbleLayer, () => {
      popup.close();
      popup.remove();
      this.saoOpenButton?.removeEventListener('click', () => { });
      this.closePopupButton?.removeEventListener('click', () => { });
    });
  }

  creatWellGeoJsonData(wells: ISaoRecordTableDataSource[]): IGeoJSON[] {
    let geoJsons: IGeoJSON[] = [];

    wells.forEach(well => {
      let activityOptionType = this.getActivityOptionType(well);
      let styling = this.setWellStyling(activityOptionType);
      let assignWell = <IGeoJSON>{
        type: 'Feature',
        geometry: <IGeometry>{
          type: 'Point',
          coordinates: [+well.data.wellLongitude, +well.data.wellLatitude],
        },
        properties: <IProperties>{
          assetTeam: well.data.assetTeam,
          devArea: well.data.developmentArea,
          subDevArea: well.data.subDevelopmentArea,
          padName: well.data.padName,
          wellName: well.data.externalWellName,
          wellId: well.id,
          style: styling
        }
      }
      geoJsons.push(assignWell);
    });

    return geoJsons;
  }

  private setWellStyling(optionType: OptionType | null): IStyle {
    switch (optionType) {
      case OptionType.Planned:
        return <IStyle>{
          radius: 3,
          strokeColor: "#000000",
          strokeWidth: 2,
          color: "#ffffff"
        };
      case OptionType.Acquired:
        return <IStyle>{
          radius: 3,
          strokeColor: "#000000",
          strokeWidth: 2,
          color: "#000000"
        };
      case null:
        return <IStyle>{
          radius: 3,
          strokeColor: "#000000",
          strokeWidth: 2,
          color: "#ffc107"
        };
    }
  }

  private getActivityOptionType(well: ISaoRecordTableDataSource): OptionType | null {
    if (well.data.assignedSaoOptions.every(opt => opt.optionType === OptionType.Planned)) {
      return OptionType.Planned;
    } else if (well.data.assignedSaoOptions.every(opt => opt.optionType === OptionType.Acquired)) {
      return OptionType.Acquired;
    }

    return null;
  }

  addPolygonLayer(dataSource: any, polygonColorSchema: IPolygonColorSchema): [string, string] {
    let polygonleLayer = new this.atlas.layer.PolygonLayer(dataSource, null, {
      fillColor: polygonColorSchema.fillColor,
      fillOpacity: polygonColorSchema.fillOpacity,
      filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
    });

    let polygonLineLayer = new this.atlas.layer.LineLayer(dataSource, null, {
      strokeColor: polygonColorSchema.strokeColor,
      strokeWidth: polygonColorSchema.strokeWidth,
      filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
    });

    this.map.layers.add([polygonleLayer, polygonLineLayer]);

    return [polygonleLayer.id, polygonLineLayer.id]
  }

  setFocusOnCenter(points: number[][]) {
    const bounds = this.atlas.data.BoundingBox.fromPositions(points);
    this.map.setCamera({
      bounds: bounds,
      padding: 50,
      maxZoom: 20
    });
  }

  centerViewOnWellAndOpenPopup(recordSource: IDashboardTableSaoRecordSource) {
    if (recordSource.saoRecordTableDataSource) {
      if (recordSource.clickSource === 'TABLE') {
        this.map.setCamera({
          center: [
            +recordSource.saoRecordTableDataSource.data.wellLongitude,
            +recordSource.saoRecordTableDataSource.data.wellLatitude
          ],
          zoom: 14
        });
      }

      let content = this.popupTemplate
        .replace(/{assetTeam}/g, recordSource.saoRecordTableDataSource.data.assetTeam)
        .replace(/{devArea}/g, recordSource.saoRecordTableDataSource.data.developmentArea)
        .replace(/{subDevArea}/g, recordSource.saoRecordTableDataSource.data.subDevelopmentArea)
        .replace(/{padName}/g, recordSource.saoRecordTableDataSource.data.padName)
        .replace(/{wellName}/g, recordSource.saoRecordTableDataSource.data.externalWellName)
        .replace(/{wellID}/g, recordSource.saoRecordTableDataSource.id);

      this.mapPopup.setOptions({
        content: content,
        position: [
          +recordSource.saoRecordTableDataSource.data.wellLongitude,
          +recordSource.saoRecordTableDataSource.data.wellLatitude
        ]
      });

      this.mapPopup.open(this.map);
      this.addPopupEvents();
    }
  }

  private emitWellIdOnClick(wellId: string) {
    if (wellId) {
      this.clickedWellId.emit(wellId);
    }
  }

  private addPopupEvents() {
    this.saoOpenButton = document.getElementById('openSaoOptionsButton');
    if (!this.saoOpenButton?.hasAttribute('sao-btn-click-listener')) {
      this.saoOpenButton?.setAttribute('sao-btn-click-listener', 'true');
      this.saoOpenButton?.addEventListener('click', () => {
        this.openDialogSaoOption(this.saoOpenButton?.dataset['saoWellId']);
      });
    }

    this.closePopupButton = document.getElementById('closePopupBtn');
    if (!this.closePopupButton?.hasAttribute('close-popup-btn-click-listener')) {
      this.closePopupButton?.setAttribute('close-popup-btn-click-listener', 'true');
      this.closePopupButton?.addEventListener('click', () => {
        this.mapPopup.close();
      });
    }
  }

  private openDialogSaoOption(wellId: string | undefined) {
    if (wellId) {
      this.openSaoOptionDialog.emit(wellId);
    }
  }

  private clearUserLayers(layers: any, layersExclusion: string[]) {
    layers?.userLayers?.forEach((userLayer: any) => {
      if (layersExclusion.indexOf(userLayer.layer.id) === -1) {
        this.map.layers.remove(userLayer.layer.id);
      }
    });
  }

  cleanUserLayerByIds(layers: [string, string]) {
    if (layers !== undefined && layers.length > 0) {
      layers.forEach(((layer: string) => {
        this.map.layers.remove(layer);
      }));
    }
  }

  readGeoJsonFileFromAssets(geoJsonFileName: string): Observable<any> {
    return this.httpService.httpGet<any>(`assets/map_shapes/${geoJsonFileName}`);
  }
}

export enum GeoJsonAreasFilesDefinition {
  BASINS = "basins.geojson",
  DEV_AREAS = "dev-areas.geojson",
  SUB_DEV_AREAS = "sub-dev-areas.geojson",
  NOJV_DEV_AREAS = "novj-dev-areas.geojson",
  PDEV_DEV_AREAS = "pdev-areas.geojson"
}

export class Basins {
  readonly fileName: string = GeoJsonAreasFilesDefinition.BASINS;
}
export class DevAreas {
  readonly fileName: string = GeoJsonAreasFilesDefinition.DEV_AREAS;
}
export class SubDevAreas {
  readonly fileName: string = GeoJsonAreasFilesDefinition.SUB_DEV_AREAS;
}
export class NojvDevAreas {
  readonly fileName: string = GeoJsonAreasFilesDefinition.NOJV_DEV_AREAS;
}
export class PdevAreas {
  readonly fileName: string = GeoJsonAreasFilesDefinition.PDEV_DEV_AREAS;
}

export type GeoJsonAreasFile =
  Basins | DevAreas | SubDevAreas | NojvDevAreas | PdevAreas;
