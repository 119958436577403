import { HttpClient, HttpRequest } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FilePreviewComponent } from 'src/app/components/file-preview/file-preview.component';
import { GenericDialogService } from 'src/app/components/generic-dialog/generic-dialog.service';
import { IFileInfoResponse, IFileVersionUpdate, EventType } from 'src/app/models/files/file-info-upload';
import { IOsduSignedUrl } from 'src/app/models/osdu/osdu-signed-url.model';
import { EditServiceEvent } from 'src/app/services/edit-service-event.service';
import { PdfViewerService } from 'src/app/services/pdf-viewer.service';
import { SaoIngestionEndpoints, getSaoIngestionEndpointUrl } from 'src/app/util/api-definitions.util';

@Component({
  selector: 'sao-options-file-version',
  templateUrl: './sao-options-file-version.component.html',
  styleUrls: ['./sao-options-file-version.component.scss']
})
export class SaoOptionsFileVersionComponent {
  @Input()
  fileVersionInput!: IFileInfoResponse[] | undefined;
  @Output()
  deleteVersion: EventEmitter<IFileVersionUpdate> = new EventEmitter<IFileVersionUpdate>();
  fileVersionDataSource!: MatTableDataSource<IFileInfoResponse>;
  sortingColumn!: string;
  sortingDirection!: string;
  previewViewerAllowExt: string[] = ['PPT', 'PPTX', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF'];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private pdfViewerService: PdfViewerService,
    private dialog: GenericDialogService,
    private dialogService: GenericDialogService,
    public router: Router,
    private matDialog: MatDialog,
    private httpClient: HttpClient,
    private editServiceEvent: EditServiceEvent) {
    this.fileVersionDataSource = new MatTableDataSource<IFileInfoResponse>(this.fileVersionInput);

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes["fileVersionInput"].previousValue) !== JSON.stringify(changes["fileVersionInput"].currentValue)) {
      this.fileVersionDataSource = new MatTableDataSource<IFileInfoResponse>(this.fileVersionInput);
      this.changeDetectorRef.detectChanges();
    }
  }

  shouldDisplayPreviewButton(row: any): boolean {
    let extension = row?.data?.extensionProperties?.fileContentsDetails?.fileType;
    if (row?.data?.hasOwnProperty('extensionProperties')) {
      let matchingExtensions = this.previewViewerAllowExt.filter(x => x.toLowerCase() == extension?.toLowerCase());
      return matchingExtensions.length > 0
    }
    return false;
  }

  shouldDisplayDownloadButton(row: any): boolean {
    if (row?.data?.hasOwnProperty('signedUrl') && row?.data?.signedUrl) {
      return true;
    }
    return false;
  }

  shouldDisplayDeleteButton() {
    if (this.router.url === '/records'){
      return false;
    }
    return true;
  }

  sortData(event: Sort) {
    const { active, direction } = event;

    // Update the sorting column and direction
    this.sortingColumn = active;
    this.sortingDirection = direction;

    let sorted = this.sortfileVersion(this.fileVersionDataSource.data);
    // Sort the data using the custom sorting function
    this.fileVersionDataSource = new MatTableDataSource<IFileInfoResponse>(sorted);
    this.changeDetectorRef.detectChanges();
  }

  private sortfileVersion(data: IFileInfoResponse[]): IFileInfoResponse[] {
    return [...data]?.sort((a, b) => this.customSort(a, b, this.sortingColumn));
  }

  customSort(a: any, b: any, sortingColumn: string): number {
    const sortingColumns = sortingColumn.split('.');
    const valueA = this.getPropertyValue(a, sortingColumns.length > 0 ? sortingColumns : [sortingColumn]);
    const valueB = this.getPropertyValue(b, sortingColumns.length > 0 ? sortingColumns : [sortingColumn]);

    if (valueA < valueB) {
      return this.sortingDirection === 'asc' ? -1 : 1;
    } else if (valueA > valueB) {
      return this.sortingDirection === 'asc' ? 1 : -1;
    } else {
      return 0;
    }
  }

  private compare(a: string | null, b: string | null, isAsc: boolean): number {
    let sortDirection = isAsc ? 1 : -1;
    let valueA = a === null ? '' : a.toLowerCase();
    let valueB = b === null ? '' : b.toLowerCase();

    let compareResult = valueA?.localeCompare(valueB);

    return compareResult * sortDirection;
  }

  getPropertyValue(obj: any, properties: string[]): any {
    const property = properties.shift();

    if (property !== undefined && obj.hasOwnProperty(property)) {
      const value = obj[property];

      if (properties.length === 0) {
        return value;
      } else {
        return this.getPropertyValue(value, properties);
      }
    }

    return null;
  }

  downloadFileVersion(event: any, row: IFileInfoResponse) {

    let payload = <IOsduSignedUrl>{
      OsduSignedUrl: row.data.signedUrl
    };

    this.pdfViewerService.downloadPdfFile(payload).subscribe({
      next: (res: any) => {
        let file = new Blob([res], { type: 'application/pdf' });
        URL.createObjectURL(file);

      },
      error: async (err: any) => {
        console.log('HTTP Error', err)
        let msg = 'Download file failed!';
        await this.dialog.openGenericDialog('Error', msg, 'Ok');
      },
      complete: () => {
        console.log('Download file completed.');
      }
    });

  }

  openFilePreview(event: Event, fileData: IFileInfoResponse) {
    this.matDialog.open(FilePreviewComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      data: {
        file: {
          fileName: fileData.data.datasetProperties.fileSourceInfo.name,
          fileType: fileData.data.extensionProperties.fileContentsDetails.fileType,
          signedUrl: fileData.data.signedUrl
        }
      }
    });
  }

  async deleteFileVersion(row: IFileInfoResponse) {
    if (row?.data?.hasOwnProperty('datasetProperties')) {
      let msg = 'Deleting the attachment associated with the SA&O record will cause submission to OSDU of all current changes, do you would like to continue?';
      let userResponse = await this.dialogService.openGenericDialog('Warning', msg, 'Cancel,Ok');
      if (userResponse) {
        let payload = [
          row.osduFileId
        ];
        let url = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.DELETE_FILE_VERSIONS);
        let request = new HttpRequest('POST', url, payload, {
          reportProgress: false
        });

        this.httpClient.request(request).subscribe({
          next: (event: any) => {
            if (event?.body && JSON.stringify(event.body).includes('removedFileVersions')) {
              console.log(`Deleted File: ${row.osduFileId}`)
            }
          },
          error: (error: any) => {
            console.error(error);
            let msg = 'File deletion failed!';
            (async () => {
              await this.dialog.openGenericDialog('Error', msg, 'Ok');
            })();
          },
          complete: () => {
            console.log(`Completed deletion of the file: ${row.osduFileId}`);
            this.deleteFileVersionFromModel(row);
            this.editServiceEvent.editRecord(userResponse);
          }
        });
      }
    } else {
      this.deleteFileVersionFromModel(row);
    }
  }

  private deleteFileVersionFromModel(row: IFileInfoResponse): void {
    let updatedFileVersions = this.fileVersionDataSource.data.filter(x =>
      x.tempFileGuid !== row.tempFileGuid && x.tempVersionGuid === row.tempVersionGuid
      && x.tempOptionGuid === row.tempOptionGuid);

    let fileVersionCounter = updatedFileVersions.filter(x => x.tempVersionGuid === row.tempVersionGuid).length;

    if (fileVersionCounter > 0) {
      this.deleteFileVersionEventEmit(row, 'UpdateFile');
    } else {
      this.deleteFileVersionEventEmit(row, 'UpdateVersion');
    }

    this.fileVersionDataSource = new MatTableDataSource<IFileInfoResponse>(updatedFileVersions);
    this.changeDetectorRef.detectChanges();
  }

  private deleteFileVersionEventEmit(fileInfoResponse: IFileInfoResponse, eventType: EventType) {
    let fileVersionUpdate = <IFileVersionUpdate>{
      tempFileGuid: fileInfoResponse.tempFileGuid,
      tempVersionGuid: fileInfoResponse.tempVersionGuid,
      tempOptionGiud: fileInfoResponse.tempOptionGuid,
      eventType: eventType
    }
    this.deleteVersion.emit(fileVersionUpdate);
  }

  fileVersionsOptColumns = [
    {
      columnDef: "versionComment",
      header: "Comment",
      cell: (option: any) => `${option.versionComment}`
    },
    {
      columnDef: "uploadedTime",
      header: "Uploaded On",
      cell: (option: any) => {
        if (option.createTime == '' || option.createTime == null)
          return '';
        const date = new Date(option.createTime);
        const formattedDate = date.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        });
        return `${formattedDate}`;
      }
    },
    {
      columnDef: "uploadedUser",
      header: "Uploaded By",
      cell: (option: any) => `${option.createUser}`
    },
    {
      columnDef: "actions",
      header: "Operations",
      cell: (option: any) => { },
    },
  ];

  displayedFileVesionsOptCols = this.fileVersionsOptColumns.map(c => c.columnDef);

}
