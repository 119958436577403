import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { ISaoFlattenedRecordDataSource } from 'src/app/models/sao-record-flat-table.model';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { IDashboardFilterSaoRecordDataSource } from '../../filter/filter.component';
import { flattenSaoRecords } from 'src/app/util/helpers.util';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'src/app/store/reducers';

export enum ClickSourceEnum {
  MAP = 'MAP', 
  TABLE = 'TABLE'
}

export type ClickSource = ClickSourceEnum.MAP | ClickSourceEnum.TABLE;

export interface IDashboardTableSaoRecordSource {
  saoRecordTableDataSource: ISaoRecordTableDataSource,
  clickSource: ClickSource
}

@Component({
  selector: 'sao-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit, OnDestroy {

  @Input()
  recordsState!: Observable<IDashboardFilterSaoRecordDataSource>;

  @Output()
  recordData: EventEmitter<IDashboardTableSaoRecordSource> = new EventEmitter<IDashboardTableSaoRecordSource>();

  @Input()
  clickedWellData!: Observable<ISaoRecordTableDataSource>;

  private optionsCacheState: Observable<ISaoOptionsCacheState>;
  private optionsData: ISaoOption[] = [];
  private subscriptions: Subscription[] = [];
  resultsLength: number = 0;

  saoRecordsDataSource: MatTableDataSource<ISaoFlattenedRecordDataSource>;
  saoRecordsTableSelection: SelectionModel<ISaoFlattenedRecordDataSource> = new SelectionModel<ISaoFlattenedRecordDataSource>(true, []);
  @ViewChild('recordPaginator') private readonly paginator!: MatPaginator;

  constructor(private cd: ChangeDetectorRef,
    store: Store<IAppState>,) {
    this.saoRecordsDataSource = new MatTableDataSource<ISaoFlattenedRecordDataSource>();
    this.optionsCacheState = store.pipe(select('saoOptionsCacheState'));
  }

  ngAfterViewInit(): void {
    this.getOptions();
    this.getRecordsData();
    this.subscribeToWellOnMapClicked();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get ClickSourceEnum(): typeof ClickSourceEnum {
    return ClickSourceEnum;
  }

  getRecordsData(): void {
    let sub = this.recordsState.subscribe(state => {
      let flattenTableResult = state.filteringResult.filteredFlattenedRecords;
      this.saoRecordsTableSelection.clear();
      this.resultsLength = flattenTableResult.length;
      this.saoRecordsDataSource.data = flattenTableResult;
      this.saoRecordsDataSource.paginator = this.paginator;
    });
    this.subscriptions.push(sub);
  }

  getOptions() {
    let sub = this.optionsCacheState.subscribe(state => {
      this.optionsData = state.options;
    }
    )
    this.subscriptions.push(sub);
  }

  onSaoRecordsRowClick(record: ISaoRecordTableDataSource, source: ClickSource) {
    if (source === ClickSourceEnum.MAP) {
      this.saoRecordsTableSelection.clear();
      this.saoRecordsDataSource.data.forEach(r => {
        if (r.data.externalPropertyId === record.data.externalPropertyId) {
          this.saoRecordsTableSelection.select(r);
        }
      });
    } else {
      let flatten = flattenSaoRecords([record], this.optionsData);
      this.saoRecordsTableSelection.clear();
      this.saoRecordsTableSelection.select(flatten[0]);
    }

    let recordDataSource = <IDashboardTableSaoRecordSource>{
      saoRecordTableDataSource: record,
      clickSource: source
    };
    this.recordData.emit(recordDataSource);
  }

  subscribeToWellOnMapClicked() {
    let sub = this.clickedWellData.subscribe(well => {
      if (well.data !== null) {
        this.selectRowByWell(well, ClickSourceEnum.MAP);
      }
    });
    this.subscriptions.push(sub);
  }

  private selectRowByWell(wellData: ISaoRecordTableDataSource, source: ClickSource) {
    let index = this.saoRecordsDataSource.data.findIndex((well) => well.id === wellData.id);
    if (index > -1) {
      this.saoRecordsTableSelection.clear();
      let pageIndex = Math.floor(index / this.paginator.pageSize);
      this.paginator.pageIndex = pageIndex;
      this.paginator._changePageSize(this.paginator.pageSize);
      this.onSaoRecordsRowClick(wellData, source);
    }
  }

  private sortDataByDevAre(records: ISaoRecordTableDataSource[]): ISaoRecordTableDataSource[] {
    return records.slice().sort((a, b) => {
      return this.compare(a.data.developmentArea, b.data.developmentArea, true);
    });
  }

  private compare(a: string | null, b: string | null, isAsc: boolean): number {
    let sortDirection = isAsc ? 1 : -1;
    let valueA = a === null ? '' : a?.toLowerCase();
    let valueB = b === null ? '' : b?.toLowerCase();

    let compareResult = valueA?.localeCompare(valueB);

    return compareResult * sortDirection;
  }

  recordsColumns = [
    {
      columnDef: "activityType",
      header: "Activity Type",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.activityType}`
    },
    {
      columnDef: "activity",
      header: "Activity",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.activityName}`
    },
    {
      columnDef: "developmentArea",
      header: "Dev Area",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.developmentArea}`
    },
    {
      columnDef: "subDevelopmentArea",
      header: "Sub Dev Area",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.subDevelopmentArea}`
    },
    {
      columnDef: "padName",
      header: "Pad Name",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.padName}`
    },
    {
      columnDef: "externalWellName",
      header: "Well Name",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.preferredWellName}`
    },
    {
      columnDef: "ApiNumber",
      header: "API / Property ID",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.apiPropertyId}`
    },
    {
      columnDef: "targetFormation",
      header: "Tgt Formation",
      cell: (record: ISaoFlattenedRecordDataSource) => `${record.data.targetFormation}`
    }
  ];

  displayedRecordsColumns = this.recordsColumns.map(c => c.columnDef);

}
