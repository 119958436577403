<div mat-dialog-title class="dialog-title">
  <div class="item1">
    <h2 class="well-name">{{getWellName()}}</h2>
  </div>
  <button class="item2" type="button" mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <table><!--/* //NOSONAR */-->
    <tr>
      <td class="col1">Asset Team:</td>
      <td class="col2">{{saoRecord.data.assetTeam}}</td>
    </tr>
    <tr>
      <td class="col1">Dev Area:</td>
      <td class="col2">{{saoRecord.data.developmentArea}}</td>
    </tr>
    <tr>
      <td class="col1">Sub Dev Area:</td>
      <td class="col2">{{saoRecord.data.subDevelopmentArea}}</td>
    </tr>
    <tr>
      <td class="col1">Pad Name:</td>
      <td class="col2">{{saoRecord.data.padName}}</td>
    </tr>
  </table>
  <p>&nbsp;</p>
  <div class="flex-container">
    <div class="planned">
      <p>Planned</p>
      <mat-table class="mat-elevation-z8" [dataSource]="plannedOptsDataSource">
        <ng-container matColumnDef="{{plannedOptColumns[0].columnDef}}">
          <mat-header-cell *matHeaderCellDef>{{plannedOptColumns[0].header}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.optionName}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{plannedOptColumns[1].columnDef}}">
          <mat-header-cell *matHeaderCellDef>{{plannedOptColumns[1].header}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.dataAcquisitionTime}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{plannedOptColumns[2].columnDef}}">
          <mat-header-cell *matHeaderCellDef>{{plannedOptColumns[2].header}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div>{{element.addedBy}}</div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedPlannedOptCols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedPlannedOptCols"></mat-row>
      </mat-table>
    </div>
    <div class="acquired">
      <p>Acquired</p>
      <mat-table class="mat-elevation-z8" [dataSource]="acquiredOptsDataSource">
        <ng-container matColumnDef="{{acqOptionsColumns[0].columnDef}}">
          <mat-header-cell *matHeaderCellDef>{{acqOptionsColumns[0].header}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.optionName}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{acqOptionsColumns[1].columnDef}}">
          <mat-header-cell *matHeaderCellDef>{{acqOptionsColumns[1].header}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.dataAcquisitionTime}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{acqOptionsColumns[2].columnDef}}">
          <mat-header-cell *matHeaderCellDef>{{acqOptionsColumns[2].header}}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="ellipsis">
            <div>{{element.addedBy}}</div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{acqOptionsColumns[3].columnDef}}">
          <mat-header-cell *matHeaderCellDef>{{acqOptionsColumns[3].header}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div><mat-icon mat-list-icon class="icon-display">attach_file</mat-icon>{{getFilesCount(element)}}</div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedAcqOptCols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedAcqOptCols"></mat-row>
      </mat-table>
    </div>
  </div>
</mat-dialog-content>
