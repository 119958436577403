export enum EnvironmentNames {
    LOCAL = 'LOCAL',
    DEV = 'DEV',
    DEV1 = 'DEV1',
    TEST = 'TEST',
    TEST1 = 'TEST1',
    STAGE = 'STAGE',
    STAGE1 = 'STAGE1',
    PROD = 'PROD',
    PROD1 = 'PROD1',
}