import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { IAppConfig } from '../models/sao-appconfig.model';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;

    constructor(private http: HttpClient) {}

    load() {
        const appConfFile = `assets/config.json`;
        return new Promise<void>((resolve, reject) => {

            // App Config
            this.http.get(appConfFile).toPromise().then((response: any) => {
                AppConfig.settings = response;
                resolve();
                
            }).catch((response: any) => {
                reject(`Could not load file '${appConfFile}': ${JSON.stringify(response)}`);
            });

 
        });
    }
}
