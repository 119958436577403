import { DatePipe, formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { Options } from 'mat-table-exporter';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { IExportCsvModel } from 'src/app/models/export-csv.model';
import { IFilter } from 'src/app/models/filter-models/filter.model';
import { IBasin } from 'src/app/models/osdu/basin.model';
import { IField } from 'src/app/models/osdu/field.model';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { OptionType } from 'src/app/models/osdu/sao-record.model';
import { ITargetFormation } from 'src/app/models/osdu/target-formation.model';
import { ISaoFlattenedRecordDataSource } from 'src/app/models/sao-record-flat-table.model';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { ExportCsvReportService } from 'src/app/services/export-csv-report.service';
import { FilterService } from 'src/app/services/filter.service';
import { SaoRecordsDataSourceService } from 'src/app/services/sao-records-data-source.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { IAppState } from 'src/app/store/reducers';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { FilterParams, FilteringResult, RecordsFilter } from 'src/app/util/sao-records-filter.util';

export interface IDashboardFilterSaoRecordDataSource {
  filteringResult: FilteringResult,
  loading: boolean
}

@Component({
  selector: 'sao-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  assetTeamForm = new FormControl();
  devAreaForm = new FormControl();
  subDevAreaForm = new FormControl();
  tgtFormationsForm = new FormControl();
  optionsForm = new FormControl();
  activityOptionsForm = new FormControl();

  filterByAttachment: boolean = false;
  filterByAttachmentTooltip: string = 'Show records without attached files';
  quickSearchInputValue: string = "";
  basinRecords: IBasin[] = [];
  basinLoading: boolean = true;
  devAreaDropdownList: IField[] = [];
  subDevAreaDropdownList: IField[] = [];
  tgtFormationsDropdownList: ITargetFormation[] = [];
  tgtFormationsLoading: boolean = true;
  optionsList: ISaoOption[] = [];
  optionsLoading: boolean = true;
  saoActivityOptionsDropdownList = Object.values(OptionType);

  fieldOsduRecords: IField[] = [];
  fieldOsduRecordsLoading: boolean = true;
  subFieldOsduRecords: IField[] = [];
  subFieldOsduRecordsLoading: boolean = true;

  saoRecordsDataSource: ISaoRecordTableDataSource[] = [];
  saoRecordsTableDataSource: MatTableDataSource<ISaoFlattenedRecordDataSource> = new MatTableDataSource<ISaoFlattenedRecordDataSource>();
  recordDataSourceLoading: boolean = true;
  filterLoading: boolean = true;

  private assetTeamSelectedState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private devAreaSelectedState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private basinsFilterLoadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private devAreaFilterLoadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private subDevAreaFilterLoadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private tgtFormationsFilterLoadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private optionsFilterLoadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private recordsFilterLoadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private filterLoadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @Output()
  filterData: EventEmitter<IDashboardFilterSaoRecordDataSource> = new EventEmitter<IDashboardFilterSaoRecordDataSource>();

  @ViewChild("assetTeamDropdown") assetTeamDropdownControl!: MatSelect;
  @ViewChild("devAreaDropdown") devAreaDropdownControl!: MatSelect;
  @ViewChild("subDevAreaDropdown") subDevAreaDropdownControl!: MatSelect;
  @ViewChild("tgtFormationsDropdown") tgtFormationsDropdownControl!: MatSelect;
  @ViewChild("saoOptionsDropdown") saoOptionsDropdownControl!: MatSelect;
  @ViewChild("activityOptionsDropdown") activityOptionsDropdownControl!: MatSelect;
  saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;

  constructor(
    private filterService: FilterService,
    store: Store<IAppState>,
    private recordsDataSourceService: SaoRecordsDataSourceService,
    private sessionStorageService: SessionStorageService,
    private exportCsvReportService: ExportCsvReportService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef) {
    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));
  }

  ngOnInit(): void {
    this.setAttachedFilesState();
    this.setQuickSearchValue();
    this.getBasins();
    this.getDevAreas();
    this.getSubDevAreas();
    this.getTgtFromations();
    this.getSaoOptions();
    this.setSaoActivityTypeValue();
    this.getSaoRecordDataSource();
    this.getSaoRecordDataSourceState();
  }

  ngAfterViewInit(): void {
    this.devAreaDropdownControl.disabled = true;
    this.subDevAreaDropdownControl.disabled = true;
    this.setDevAreaMatSelectState();
    this.setSubDevAreaMatSelectState();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setAttachedFilesState() {
    let attachedFiles = this.sessionStorageService.getFilterStateFromSessionStorage()?.attachedFiles;
    this.filterByAttachment = attachedFiles;
    if (attachedFiles) {
      this.filterByAttachmentTooltip = 'Show all records';
    } else {
      this.filterByAttachmentTooltip = 'Show records without attached files';
    }
  }

  setQuickSearchValue() {
    let quickSearchValue = this.sessionStorageService.getFilterStateFromSessionStorage()?.quickSearch;
    this.quickSearchInputValue = quickSearchValue;
  }

  getBasins(): void {
    let sub = this.filterService.getBasinsObservable().subscribe(basinsState => {
      this.basinRecords = basinsState.basins;
      this.basinLoading = basinsState.loading;

      // set seletion on assetTeam
      let basinName = this.sessionStorageService.getFilterStateFromSessionStorage()?.assetTeam?.data.basinName;
      if (basinName) {
        this.basinRecords.forEach(basin => {
          if (basin.data.basinName === basinName) {
            this.assetTeamForm.setValue(basin);
            this.assetTeamSelected = basin;
            this.assetTeamForm.updateValueAndValidity();
          }
        });
      }
      this.assetTeamSelectedState.next(
        this.assetTeamSelected !== undefined
      );
      this.basinsFilterLoadingStateSubject.next(basinsState.loading);
    });
    this.subscriptions.push(sub);
  }

  private getAssetTeamSelectedState(): Observable<boolean> {
    return this.assetTeamSelectedState.asObservable();
  }

  private getBasinsFilterLoadingState(): Observable<boolean> {
    return this.basinsFilterLoadingStateSubject.asObservable();
  }

  getDevAreas(): void {
    let sub = this.filterService.getDevAreasObservable().subscribe(devAreasState => {
      this.fieldOsduRecords = devAreasState.fields;
      this.fieldOsduRecordsLoading = devAreasState.loading;

      // set seletion on devArea
      let fieldName = this.sessionStorageService.getFilterStateFromSessionStorage()?.devArea?.data.fieldName;
      this.devAreaDropdownList = [];
      this.devAreaDropdownList = this.getDevAreaDropdownList();
      if (fieldName) {
        this.devAreaDropdownList.forEach(devArea => {
          if (devArea.data.fieldName === fieldName) {
            this.devAreaForm.setValue(devArea);
            this.devAreaSelected = devArea;
            this.devAreaForm.updateValueAndValidity();
          }
        });
      }
      this.devAreaSelectedState.next(
        this.devAreaSelected !== undefined
      );
      this.devAreaFilterLoadingStateSubject.next(devAreasState.loading);
    });
    this.subscriptions.push(sub);
  }

  private getDevAreaSelectedState(): Observable<boolean> {
    return this.devAreaSelectedState.asObservable();
  }

  private getDevAreaFilterLoadingState(): Observable<boolean> {
    return this.devAreaFilterLoadingStateSubject.asObservable();
  }

  getSubDevAreas(): void {
    let sub = this.filterService.getSubDevAreasObservable().subscribe(subDevAreasState => {
      this.subFieldOsduRecords = subDevAreasState.fields;
      this.subFieldOsduRecordsLoading = subDevAreasState.loading;

      // set seletion on subDevArea
      this.subDevAreaDropdownList = [];
      this.subDevAreaDropdownList = this.getSubDevAreaDropdownList();
      let subDevAreasList = this.sessionStorageService.getFilterStateFromSessionStorage()?.subDevAreas;
      if (subDevAreasList && subDevAreasList.length > 0) {
        subDevAreasList.forEach(subDevAreaStore => {
          this.subDevAreaDropdownList.forEach(subDevArea => {
            if (subDevArea.data.fieldName === subDevAreaStore.data.fieldName) {
              this.subDevAreasSelected.push(subDevArea);
            }
          });
        });
        this.subDevAreaForm.setValue(this.subDevAreasSelected);
        this.subDevAreaForm.updateValueAndValidity();
      }
      this.subDevAreaFilterLoadingStateSubject.next(subDevAreasState.loading);
    });
    this.subscriptions.push(sub);
  }

  private getSubDevAreaFilterLoadingState(): Observable<boolean> {
    return this.subDevAreaFilterLoadingStateSubject.asObservable();
  }

  getTgtFromations(): void {
    let sub = this.filterService.getTargetFormationsObservable().subscribe(tgtFormationsState => {
      this.tgtFormationsDropdownList = tgtFormationsState.tgtFormations;
      this.tgtFormationsLoading = tgtFormationsState.loading;

      // set seletion on Target Formations
      let tgtFormationList = this.sessionStorageService.getFilterStateFromSessionStorage()?.tgtFormations;
      if (tgtFormationList && tgtFormationList.length > 0) {
        tgtFormationList.forEach(tgtFormationStore => {
          this.tgtFormationsDropdownList.forEach(tgtFormation => {
            if (tgtFormation.data.Name === tgtFormationStore.data.Name) {
              this.tgtFormationsSelected.push(tgtFormation);
            }
          });
        });
        this.tgtFormationsForm.setValue(this.tgtFormationsSelected);
        this.tgtFormationsForm.updateValueAndValidity();
      }
      this.tgtFormationsFilterLoadingStateSubject.next(tgtFormationsState.loading);
    });
    this.subscriptions.push(sub);
  }

  private getTgtFromationsFilterLoadingState(): Observable<boolean> {
    return this.tgtFormationsFilterLoadingStateSubject.asObservable();
  }

  getSaoOptions(): void {
    let sub = this.filterService.getSaoOpsionsObservable().subscribe(saoOptionsState => {
      this.optionsList = saoOptionsState.saoOptions;
      this.optionsLoading = saoOptionsState.loading;

      // set seletion on SAO options
      let optionsList = this.sessionStorageService.getFilterStateFromSessionStorage()?.saoOptions;
      if (optionsList && optionsList.length > 0) {
        optionsList.forEach(optionStore => {
          this.optionsList.forEach(option => {
            if (option.data.saoOptionName === optionStore.data.saoOptionName) {
              this.saoOptionsSelected.push(option);
            }
          });
        });
        this.optionsForm.setValue(this.saoOptionsSelected);
        this.optionsForm.updateValueAndValidity();
      }
      this.optionsFilterLoadingStateSubject.next(saoOptionsState.loading);
    });
    this.subscriptions.push(sub);
  }

  private getOptionsFilterLoadingState(): Observable<boolean> {
    return this.optionsFilterLoadingStateSubject.asObservable();
  }

  setSaoActivityTypeValue() {
    let activityTypeValue = this.sessionStorageService.getFilterStateFromSessionStorage()?.activityType;

    if (activityTypeValue !== undefined) {
      this.saoActivityOptionsDropdownList.forEach(activity => {
        if(activity.toString() === activityTypeValue){
          this.activityOptionsForm.setValue(activity);
          this.activityTypeSelected = activityTypeValue;
          this.activityOptionsForm.updateValueAndValidity();
        }
      })
    }
  }

  getSaoRecordDataSource(): void {
    let sub = this.recordsDataSourceService.getSaoRecordDataSource().subscribe(recordsDataSource => {
      this.recordDataSourceLoading = recordsDataSource.loading;
      if (!recordsDataSource.loading) {
        this.saoRecordsDataSource = recordsDataSource.records;
      }
      this.recordsFilterLoadingStateSubject.next(recordsDataSource.loading);
    });
    this.subscriptions.push(sub);
  }

  getSaoRecordDataSourceState(): void {
    this.getFilterLoadingState().subscribe(filterLoading => {
      if (!filterLoading) {
        this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
          filteringResult: this.applyAllFilters(),
          loading: filterLoading
        });
      }
      this.filterLoading = filterLoading;
    });
  }

  private getRecordsFilterLoadingState(): Observable<boolean> {
    return this.recordsFilterLoadingStateSubject.asObservable();
  }

  private combineFilterLoadingState(): Observable<boolean[]> {
    return combineLatest(
      [
        this.getBasinsFilterLoadingState(),
        this.getDevAreaFilterLoadingState(),
        this.getSubDevAreaFilterLoadingState(),
        this.getTgtFromationsFilterLoadingState(),
        this.getOptionsFilterLoadingState(),
        this.getRecordsFilterLoadingState()
      ]);
  }

  private filterLoadingState() {
    this.combineFilterLoadingState().subscribe(state => {
      let filterState = state[0]
        || state[1]
        || state[2]
        || state[3]
        || state[4]
        || state[5];

      this.filterLoadingStateSubject.next(filterState);
    });
  }

  getFilterLoadingState(): Observable<boolean> {
    this.filterLoadingState();
    return this.filterLoadingStateSubject.asObservable();
  }

  resetFiltersClicked() {
    this.resetAllFilters();
  }

  filterByAttachedFiles() {
    this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
      filteringResult: this.applyAllFilters(),
      loading: this.filterLoading
    });
    this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    if (this.filterByAttachment) {
      this.filterByAttachmentTooltip = 'Show all records';
    } else {
      this.filterByAttachmentTooltip = 'Show records without attached files';
    }
  }

  applyQuickSearchFilter(event: Event) {
    this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
      filteringResult: this.applyAllFilters(),
      loading: this.filterLoading
    });
    this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
  }

  assetTeamSelected!: IBasin | undefined;
  onBasinSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.cleardevAreaAndSubDevAreaDropdownSelection();
      this.assetTeamSelected = event.source.value;
      this.devAreaDropdownControl.disabled = true;
      this.subDevAreaDropdownControl.disabled = true;
      this.devAreaDropdownList = [];
      this.devAreaDropdownList = this.getDevAreaDropdownList();
      this.subDevAreaDropdownList = [];

      this.devAreaDropdownControl.disabled = false;
      this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
        filteringResult: this.applyAllFilters(),
        loading: this.filterLoading
      });
      this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    }
  }

  private getDevAreaDropdownList(): IField[] {
    return this.fieldOsduRecords.filter(field =>
      field.data.geoContexts[0]?.basinId == this.assetTeamSelected?.id);
  }

  private setDevAreaMatSelectState() {
    this.getAssetTeamSelectedState().subscribe(state => {
      this.devAreaDropdownControl.disabled = !state;
    });
  }

  devAreaSelected!: IField | undefined;
  onDevAreaSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.subDevAreaDropdownControl.options.forEach(option => option.deselect());
      this.subDevAreasSelected = [];
      this.devAreaSelected = event.source.value;
      this.subDevAreaDropdownControl.disabled = true;
      this.subDevAreaDropdownList = [];
      this.subDevAreaDropdownList = this.getSubDevAreaDropdownList();

      this.subDevAreaDropdownControl.disabled = false;
      this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
        filteringResult: this.applyAllFilters(),
        loading: this.filterLoading
      });
      this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    }
  }

  private getSubDevAreaDropdownList(): IField[] {
    return this.subFieldOsduRecords.filter(subField =>
      subField.data.geoContexts[0].basinId == this.assetTeamSelected?.id
      && subField.data.geoContexts[0]?.fieldId == this.devAreaSelected?.id
    );
  }

  private setSubDevAreaMatSelectState() {
    this.getDevAreaSelectedState().subscribe(state => {
      this.subDevAreaDropdownControl.disabled = !state;
    });
  }

  subDevAreasSelected: IField[] = [];
  onSubDevAreaSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.subDevAreasSelected.push(event.source.value);
        this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
          filteringResult: this.applyAllFilters(),
          loading: this.filterLoading
        });
        this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
      }
      else {
        let toRemove = this.subDevAreasSelected.indexOf(event.source.value);
        this.subDevAreasSelected.splice(toRemove, 1);
        this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
          filteringResult: this.applyAllFilters(),
          loading: this.filterLoading
        });
        this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
      }

    }
  }

  tgtFormationsSelected: ITargetFormation[] = [];
  onTargetFormationSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput && event.source.selected) {
      this.tgtFormationsSelected.push(event.source.value);
      this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
        filteringResult: this.applyAllFilters(),
        loading: this.filterLoading
      });
      this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    }
    else if (event.isUserInput && !event.source.selected) {
      let toRemove = this.tgtFormationsSelected.indexOf(event.source.value);
      this.tgtFormationsSelected.splice(toRemove, 1);
      this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
        filteringResult: this.applyAllFilters(),
        loading: this.filterLoading
      });
      this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    }
  }

  saoOptionsSelected: ISaoOption[] = [];
  onSaoOptionsSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput && event.source.selected) {
      this.saoOptionsSelected.push(event.source.value);
      this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
        filteringResult: this.applyAllFilters(),
        loading: this.filterLoading
      });
      this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    }
    else if (event.isUserInput && !event.source.selected) {
      let toRemove = this.saoOptionsSelected.indexOf(event.source.value);
      this.saoOptionsSelected.splice(toRemove, 1);
      this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
        filteringResult: this.applyAllFilters(),
        loading: this.filterLoading
      });
      this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    }
  }

  activityTypeSelected: string = "";
  onSaoActivityTypeSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.activityTypeSelected = event.source.value;
      this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
        filteringResult: this.applyAllFilters(),
        loading: this.filterLoading
      });
      this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
    }
  };

  private cleardevAreaAndSubDevAreaDropdownSelection() {
    this.devAreaDropdownControl.options.forEach(option => option.deselect());
    this.subDevAreaDropdownControl.options.forEach(option => option.deselect());
    this.devAreaSelected = undefined;
    this.subDevAreasSelected = [];
  }

  private applyAllFilters(): FilteringResult {
    let filterParams = new FilterParams(
      this.assetTeamSelected,
      this.fieldOsduRecords,
      this.devAreaSelected,
      this.subDevAreasSelected,
      this.tgtFormationsSelected,
      this.saoOptionsSelected,
      this.filterByAttachment,
      this.activityTypeSelected);
 
    let filterOutput =
      new RecordsFilter(this.saoRecordsDataSource, this.optionsList).filter(filterParams);

    return this.updateSaoRecordsTable(filterOutput);
  }

  private updateSaoRecordsTable(filteringResult: FilteringResult): FilteringResult {
    this.saoRecordsTableDataSource = new MatTableDataSource<ISaoFlattenedRecordDataSource>(filteringResult.filteredFlattenedRecords);
    this.saoRecordsTableDataSource.filterPredicate = this.customRecordsTableFilterPredicate;
    this.saoRecordsTableDataSource.filter = this.quickSearchInputValue.trim().toLowerCase();

    let filteringResultWithQuickSearch: FilteringResult = {
      filteredRecords: filteringResult.filteredRecords,
      filteredFlattenedRecords: this.saoRecordsTableDataSource.filteredData
    }

    return filteringResultWithQuickSearch;
  }

  private customRecordsTableFilterPredicate(record: ISaoFlattenedRecordDataSource, filter: string): boolean {
    let fracStartDateString = (record.data.fracStartDate === undefined || record.data.fracStartDate == "") ? "" : formatDate(record.data.fracStartDate, 'yyyy-MM-dd', 'en-US');
    let spudDateString = (record.data.spudDate === undefined || record.data.spudDate == "") ? "" : formatDate(record.data.spudDate, 'yyyy-MM-dd', 'en-US');
    let forecastedPopDateString = (record.data.forecastedPop === undefined || record.data.forecastedPop == "") ? "" : formatDate(record.data.forecastedPop, 'yyyy-MM-dd', 'en-US');
    let wellName = record.data.preferredWellName;
    let apiOrPropId = record.data.apiPropertyId;
    let targetFormationCode = record.data.targetFormation;
    let activityType = record.data.activityType.toString();
    let activityName = record.data.activityName ?? "";

    let filterQuickSearch: boolean = false;

    let rowArray: Array<string> = [activityName, activityType, record.data.developmentArea, record.data.subDevelopmentArea, record.data.padName,
      wellName, apiOrPropId, targetFormationCode, fracStartDateString, spudDateString, forecastedPopDateString];

    filter = filter.replace(/([.+=!:{}()|[\]/\\])/g, "\\$1");
    const regex = new RegExp(`${filter.replace(/\*/g, '.').replace(/\?/g, '.*')}`, 'i');

    for (let cell of rowArray) {
      filterQuickSearch = regex.test(cell);
      if (filterQuickSearch) {
        return filterQuickSearch;
      }
    }

    return filterQuickSearch;
  }

  private resetAllFilters() {
    this.assetTeamSelected = undefined;
    this.devAreaSelected = undefined;
    this.subDevAreasSelected = [];
    this.saoOptionsSelected = [];
    this.tgtFormationsSelected = [];
    this.assetTeamDropdownControl.options.forEach(option => option.deselect());
    this.devAreaDropdownControl.options.forEach(option => option.deselect());
    this.subDevAreaDropdownControl.options.forEach(option => option.deselect());
    this.tgtFormationsDropdownControl.options.forEach(option => option.deselect());
    this.saoOptionsDropdownControl.options.forEach(option => option.deselect());
    this.activityOptionsDropdownControl.options.forEach(option => option.deselect());
    this.saoRecordsTableDataSource.filter = '';
    this.quickSearchInputValue = '';
    this.activityTypeSelected = '';
    this.devAreaDropdownControl.disabled = true;
    this.subDevAreaDropdownControl.disabled = true;
    this.filterByAttachment = false;
    this.filterByAttachmentTooltip = 'Show records without attached files';
    this.filterData.emit(<IDashboardFilterSaoRecordDataSource>{
      filteringResult: this.applyAllFilters(),
      loading: this.filterLoading
    });
    this.sessionStorageService.setFilterModelInSessionStorage(this.getFilterModel());
  }

  private getFilterModel(): IFilter {
    let model = <IFilter>{
      attachedFiles: this.filterByAttachment,
      quickSearch: this.quickSearchInputValue,
      assetTeam: this.assetTeamSelected,
      devArea: this.devAreaSelected,
      subDevAreas: this.subDevAreasSelected,
      tgtFormations: this.tgtFormationsSelected,
      saoOptions: this.saoOptionsSelected,
      activityType: this.activityTypeSelected
    };

    return model;
  }

  exportToCsv(): void {
    let date = this.datePipe.transform(new Date().toUTCString(), 'MM-dd-yyyy-hhmm')

    let options = {
      fileName: `sao-records-${date}`,
    } as Options;

    let records = this.getRecordsForExport();

    let aggregateRecords = records.reduce((acc, curr) => acc.concat(curr));

    if (aggregateRecords.length == 0)
      throw new Error("Nothing to download");

    let headersRow = {
      saoOptionName: "Sao Option Name",
      saoType: "Sao Type",
      wellName: "Well Name",
      apiPropertyId: "Property Id",
      devArea: "Development Area",
      subDevArea: "Sub Development Area",
      padName: "Pad Name",
      forecastPopDate: "Forecast Pop Date",
      fracStartDate: "Frac Start Date",
      spudDate: "Spud Date",
      tgtFormation: "Tgt Formation"
    } as IExportCsvModel

    let excelTemplate = [headersRow, ...aggregateRecords];
    this.exportCsvReportService.export(excelTemplate, options);
  }

  getRecordsForExport() {
    return this.saoRecordsTableDataSource.filteredData.map(record => record.data.assignedSaoOptions.map(assignedOption => {

      let saoOption = this.optionsList.find(option => option.id == assignedOption.optionId);
      if (saoOption === undefined) {
        throw new Error(`Unable to find option with ${assignedOption.optionId}`);
      }

      return {
        saoOptionName: saoOption.data.saoOptionName,
        saoType: assignedOption.optionType,
        wellName: record.data.preferredWellName,
        apiPropertyId: record.data.apiPropertyId,
        devArea: record.data.developmentArea,
        subDevArea: record.data.subDevelopmentArea,
        padName: record.data.padName,
        forecastPopDate: record.data.forecastedPop,
        fracStartDate: record.data.fracStartDate,
        spudDate: record.data.spudDate,
        tgtFormation: record.data.targetFormation,
      } as IExportCsvModel
    }
    ))
  }

}
