import { ISaoRecordsCacheState, saoRecordsReducer } from "./fetch-records.reducer";
import { ActionReducerMap } from "@ngrx/store";
import { LoginState, loginStateReducer } from "./login.reducer";
import { basinsReducer, IBasinsCacheState } from "./fetch-basins.reducer";
import { fieldsReducer, IFieldsCacheState } from "./fetch-fields.reducer";
import { fromationsReducer, IFormationsCacheState } from "./fetch-formations.reducer";
import { ISaoOptionsCacheState, optionsReducer } from "./fetch-sao-options.reducer";
import { ITargetFormationsCacheState, targetFormationsReducer } from "./fetch-tgt-formations.reducer";
import { assignedOptionReducer, IAssignedSaoOptionState } from "./assigned-option-reducer";
import { IWellsDetailsCacheState, wellsDetailsReducer } from "./fetch-wells-details-reducer";
import { IAzureMapsSettingsCacheState, azureMapsSettingsReducer } from "./fetch-azure-maps-settings.reducer";
import { ISubFieldsCacheState, subFieldsReducer } from "./fetch-sub-fields.reducer";

export interface IAppState {
  azureMapsSettingsCacheState: IAzureMapsSettingsCacheState;
  saoRecordsCacheState: ISaoRecordsCacheState;
  basinsCacheState: IBasinsCacheState;
  fieldsCacheState: IFieldsCacheState;
  subFieldsCacheState: ISubFieldsCacheState
  formationsCacheState: IFormationsCacheState;
  targetFormationsCacheState: ITargetFormationsCacheState;
  saoOptionsCacheState: ISaoOptionsCacheState;
  assignedSaoOptionState: IAssignedSaoOptionState;
  wellsDetailsCacheState: IWellsDetailsCacheState;
  loginState: LoginState;
}

export const reducers: ActionReducerMap<IAppState, any> = {
  azureMapsSettingsCacheState: azureMapsSettingsReducer,
  saoRecordsCacheState: saoRecordsReducer,
  basinsCacheState: basinsReducer,
  fieldsCacheState: fieldsReducer,
  subFieldsCacheState: subFieldsReducer,
  formationsCacheState: fromationsReducer,
  targetFormationsCacheState: targetFormationsReducer,
  saoOptionsCacheState: optionsReducer,
  assignedSaoOptionState: assignedOptionReducer,
  wellsDetailsCacheState: wellsDetailsReducer,
  loginState: loginStateReducer
}
