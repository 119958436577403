import { ISaoOption } from "../models/osdu/sao-option.model";
import { ISaoFlattenedRecordDataSource } from "../models/sao-record-flat-table.model";
import { ISaoRecordTableDataSource } from "../models/sao-record-search-table.model";

export function nameof<T>(key: keyof T, instance?: T): keyof T {
    return key;
}

export function formatTgtFormation(value: string) {
    return value.split(' ')[0];
}

/**
 * @param records array of the OSDU SA&O records
 * @param saoOptions array of the OSDU SA&O activity records
 * @returns Returns flattened representation of the SA&O OSDU records, each element of the array represents single SA&O activity from the AssignedSaoOptionsArray of the original record. 
 */
export function flattenSaoRecords(records: ISaoRecordTableDataSource[], saoOptions: ISaoOption[]): ISaoFlattenedRecordDataSource[] {
    let flattenedData: ISaoFlattenedRecordDataSource[] = [];

    records.forEach(record => record.data.assignedSaoOptions.forEach(assignedOption => {
        flattenedData.push({
            ...record,
            data: {
                ...record.data,
                assignedSaoOptions: [assignedOption], //record.data.assignedSaoOptions, //left for compatibility with base record, allows to associate with other activites for the same well on the map
                activityType: assignedOption.optionType,
                activityName: saoOptions.find(o => o.id === assignedOption.optionId)?.data.saoOptionName ?? "" 
            }
        })
    }))

    return flattenedData;
}