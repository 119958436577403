import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IAssignedSaoOptionDto } from 'src/app/models/sao-option-dto.model';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { OptionType } from 'src/app/models/osdu/sao-record.model';

@Component({
  selector: 'sao-map-options-dialog',
  templateUrl: './map-options-dialog.component.html',
  styleUrls: ['./map-options-dialog.component.scss']
})
export class MapOptionsDialogComponent implements OnInit {

  saoRecord: ISaoRecordTableDataSource;
  plannedOptions: IAssignedSaoOptionDto[] = [];
  acquiredOptions: IAssignedSaoOptionDto[] = [];
  public optionType = OptionType;
  plannedOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();
  acquiredOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.saoRecord = data.saoRecord;
  }

  ngOnInit(): void {
    this.getSaoOptionsData();
  }

  getWellName(): string {
    let wellName = this.saoRecord.data?.permittedLegalWellName ? `${this.saoRecord.data?.permittedLegalWellName} / ${this.saoRecord.data?.externalWellName}`
    : `${this.saoRecord.data?.externalWellName}`;
    return wellName;
  }

  getSaoOptionsData(): void {
    this.plannedOptions = this.data.assignedOptions.filter((option: { optionType: OptionType; }) => option.optionType === this.optionType.Planned);
    this.acquiredOptions = this.data.assignedOptions.filter((option: { optionType: OptionType; }) => option.optionType === this.optionType.Acquired);
    this.plannedOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(this.plannedOptions);
    this.acquiredOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(this.acquiredOptions);
  }

  getFilesCount(option: IAssignedSaoOptionDto): number {
    let counter = 0;
    if (option?.files.length > 0) {
      option.files.forEach(file => {
        let countFiles = file.fileVersions.length;
        counter += countFiles;
      });
      return counter;
    }
    else {
      return counter;
    }
  }

  plannedOptColumns = [
    {
      columnDef: "plannedOptioName",
      header: "Activity"
    },
    {
      columnDef: "plannedOptioDate",
      header: "Date"
    },
    {
      columnDef: "plannedOptioAddedBy",
      header: "Added By"
    }
  ];

  displayedPlannedOptCols = this.plannedOptColumns.map(c => c.columnDef);

  acqOptionsColumns = [
    {
      columnDef: "acquiredOptioName",
      header: "Activity"
    },
    {
      columnDef: "acquiredOptioDate",
      header: "Date"
    },
    {
      columnDef: "acquiredOptioAddedBy",
      header: "Added By"
    },
    {
      columnDef: "acquiredOptioFiles",
      header: ""
    }
  ];

  displayedAcqOptCols = this.acqOptionsColumns.map(c => c.columnDef);
}
