import { Injectable } from '@angular/core';
import { IField } from '../models/osdu/field.model';
import { IBasinsCacheState } from '../store/reducers/fetch-basins.reducer';
import { ISaoRecordsCacheState } from '../store/reducers/fetch-records.reducer';

@Injectable({
  providedIn: 'root'
})
export class SaoRecordService {

  getBasins(thisParent: any) {
    let sub = thisParent.basinsCacheState$.subscribe((state: IBasinsCacheState) => {
      thisParent.basinsLoading = state.basinsLoading;
      let basins = state.basins;
      if (!thisParent.basinsLoading) {
        let toSort = [...basins];
        toSort.sort((a, b) => (a.data.basinName < b.data.basinName) ? -1 : 1);
        thisParent.basinRecords = toSort;
      }
    });
    thisParent.subscriptions.push(sub);
  }

  getSaoRecords(thisParent: any) {
    let sub = thisParent.saoRecordsCacheState$.subscribe((state: ISaoRecordsCacheState) => {
      thisParent.allSaoRecords = state.records;
      thisParent.saoRecordsLoading = state.recordsLoading;
    });

    thisParent.subscriptions.push(sub);
  }

  onBasinSelectByAssetTeam(thisParent: any, value: any) {
    thisParent.assetTeamSelected = value
    thisParent.devAreaDropdownList = [];
    thisParent.subDevAreaDropdownList = [];

    thisParent.fieldOsduRecords.forEach((field: IField) => {
      if (field.data.geoContexts[0]?.basinId == thisParent.assetTeamSelected?.id) {
        thisParent.devAreaDropdownList.push(field);
      }
    })
  }

  onDevAreaSelect(thisParent: any, value: any) {
    thisParent.devAreaSelected = value;

    thisParent.subDevAreaDropdownControl.disabled = true;

    thisParent.subDevAreaDropdownList = thisParent.subFieldOsduRecords.filter((subField: IField) =>
      subField.data.geoContexts[0].basinId == thisParent.assetTeamSelected?.id
      && subField.data.geoContexts[0]?.fieldId == thisParent.devAreaSelected?.id
    );
    thisParent.subDevAreaDropdownControl.disabled = false;

  }

}
