import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { CalAngularService } from "@cvx/cal-angular";
import { NGXLogger } from "ngx-logger";
import { map, Observable } from "rxjs";
import { AppConfig as AppConfig } from "../services/app-config.service";
import { IAppConfig } from "../models/sao-appconfig.model";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    static settings: IAppConfig;
    private config: IAppConfig = {
        isLandingPage: AppConfig.settings.isLandingPage
      }
    constructor(
        private calService: CalAngularService,
        private router: Router,
        private http: HttpClient,
        private logger: NGXLogger,

    ) {

    }

    canActivate(): Observable<boolean> {
        return this.calService.isUserSignedIn().pipe(map(signedIn => {
            if (signedIn && !this.config.isLandingPage) {
                return true;
            } else {

                this.router.navigate(['login']);
                return false;
            }
        }));
    }
}