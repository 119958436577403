import { ISaoOption } from "src/app/models/osdu/sao-option.model";
import { FetchSaoOptionsAction, FetchSaoOptionsActions } from "../actions/fetch-sao-options.actions";

export interface ISaoOptionsCacheState {
    options: ISaoOption[],
    optionsLoading: boolean,
    optionsRequestError: any
}

const initialState: ISaoOptionsCacheState = {
    options: [],
    optionsLoading: false,
    optionsRequestError: null
}

export function optionsReducer(state: ISaoOptionsCacheState = initialState, action: FetchSaoOptionsAction): ISaoOptionsCacheState {
    
    switch(action.type) {
        case FetchSaoOptionsActions.SAO_OPTIONS_REQUEST: {
            state = <ISaoOptionsCacheState>{
                options: [], optionsLoading: true, optionsRequestError: null
            };
            return state;
        }
        case FetchSaoOptionsActions.SAO_OPTIONS_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchSaoOptionsActions.SAO_OPTIONS_REQUEST_FAILED: {
            state = <ISaoOptionsCacheState>{
                options: [],
                optionsLoading: false,
                optionsRequestError: action.payload.optionsRequestError
            };
            return state;
        }
        default: {
            return state;
        }
    }
}