import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { FetchSaoRecordsActions, RecordsRequestFailed, RecordsRequestSuccess } from "src/app/store/actions/fetch-records.actions";
import { environment } from "src/environments/environment";
import { IAppState } from "../reducers";
import { RequestWellsDetails } from "../actions/fetch-wells-details.action";
import { IWellsDetailsCacheState } from "../reducers/fetch-wells-details-reducer";

@Injectable()
export class FetchRecordsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) { }

    public fetchRecords$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchSaoRecordsActions.SAO_RECORDS_REQUEST),
            exhaustMap(() => {
                return this.dataService.getAllSaoRecords({kind: environment.osduSurveillanceRecordKind}).pipe(
                    map(result => {
                        let recordsRequestSuccess =  new RecordsRequestSuccess({ 
                            records: result, 
                            recordsLoading: false, 
                            recordsRequestError: null });
                        let records = recordsRequestSuccess.payload.records;
                        this.store.dispatch(new RequestWellsDetails(<IWellsDetailsCacheState>{
                            wellsDetailsList: [],
                            wellsDetailsLoading: true,
                            wellDetailsRequestError: null,
                            wellsIds: records.map(record => record.data.externalPropertyId)
                        }))
                        return recordsRequestSuccess;
                    }))
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new RecordsRequestFailed({ 
                    records: [], 
                    recordsLoading: false, 
                    recordsRequestError: error }));
                throw err;
            })
        )
    }
    );
}