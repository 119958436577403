import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { IUploadedFileDto } from '../models/sao-option-dto.model';
import { getSaoIngestionEndpointUrl, SaoIngestionEndpoints } from '../util/api-definitions.util';
import { IDurableFunctionStatus } from '../models/durable-functions/durable-function-status.model';
import { HttpService } from './http.service';
import { DurableFunctionResponseService } from './durable-function-response.service';
import { timeout, retryWhen, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {


  sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  constructor(private httpClient: HttpClient,
    private httpService: HttpService,
    private durableFunctionSerice: DurableFunctionResponseService) { }

  uploadFileToBlobStorage(files: File[],
    reportProgress: boolean): Observable<HttpEvent<any>> {
    let formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('file', file);
    })

    let url = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.UPLOAD_SAO_FILE);
    let request = new HttpRequest('POST', url, formData, {
      reportProgress: reportProgress
    });

    return this.httpClient.request(request);
  }

  uploadFileToOsdu(fileUpload: IUploadedFileDto[]): Observable<HttpEvent<any>> {
    let url = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.UPLOAD_SAO_FILE_IN_CHUNKS_TO_OSDU);
    let request = new HttpRequest('POST', url, fileUpload);
    return this.httpClient.request(request)
      .pipe(
        timeout(300000), 
        retryWhen(errors => errors.pipe(delay(1000))), 
        catchError(e => {
          console.error(e);
          return of(new HttpResponse(undefined));
        })
      );
  }

  public async uploadFileFromStageToOsdu(fileUpload: IUploadedFileDto[]): Promise<IDurableFunctionStatus> {
    let durableFunctionUrl = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.UPLOAD_SAO_FILE_IN_CHUNKS_TO_OSDU);
    return await this.durableFunctionSerice.runDurableFunctionAsync(fileUpload, durableFunctionUrl);
  }
}
