import { Action } from "@ngrx/store";
import { IAssignedSaoOptionState } from "../reducers/assigned-option-reducer";

export enum AssignedOptionActions {
    PASS_ASSIGNED_OPTION = '[ASSIGNED_SAO_OPTION] option passed to another component.',
    RESET_ASSIGNED_OPTION = '[ASSIGNED_SAO_OPTION] option received.'
}

export class PassAssignedOption implements Action {
    readonly type: string = AssignedOptionActions.PASS_ASSIGNED_OPTION;
    constructor(public payload: IAssignedSaoOptionState) {}
}

export class ResetAssignedOption implements Action {
    readonly type: string = AssignedOptionActions.RESET_ASSIGNED_OPTION;
    constructor(public payload: IAssignedSaoOptionState) {}
}

export type AssignedOptionAction
= PassAssignedOption | ResetAssignedOption