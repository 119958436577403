import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAssignedSaoOption } from 'src/app/models/osdu/sao-record.model';
import { IAppState } from 'src/app/store/reducers';
import { IAssignedSaoOptionState } from 'src/app/store/reducers/assigned-option-reducer';

@Component({
  selector: 'sao-browse-attached-files',
  templateUrl: './browse-attached-files.component.html',
  styleUrls: ['./browse-attached-files.component.scss']
})
export class BrowseAttachedFilesComponent implements OnDestroy {

  private assignedOptionState$: Observable<IAssignedSaoOptionState>;
  private assignedOption: IAssignedSaoOption | undefined;
  private subscriptions: Subscription[] = [];

  constructor(store: Store<IAppState>) { 
    this.assignedOptionState$ = store.pipe(select('assignedSaoOptionState'));
    let sub = this.assignedOptionState$.subscribe(state => {
      this.assignedOption = state.assignedSaoOption;
    })
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
